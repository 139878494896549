import React, { useEffect, useState } from "react";

import * as S from "./styles";
import { FHSB, HR, Title, WhiteTitle } from "../../../main-styles";
import { STATUS } from "../../../utils/constants";
import { PRODUTOS } from "../../../database/API/Produtos";
import Loader from "../../../components/Loader";
import ShadowBox from "../../../components/ShadowBox";
import AddBtn from "../../../components/AddBtn";
import { Badge } from "reactstrap";
import { useNavigate } from "react-router-dom";

function Produtos() {
  const navigate = useNavigate();
  const [state, setState] = useState({
    produtos: [],
    status: STATUS.LOADING,
  });
  const { produtos, status } = state;

  useEffect(() => {
    init();
    document.title = "Produtos Participantes | Admin |  Orga&Vet";
  }, []);

  async function init() {
    setState((s) => ({ ...s, status: STATUS.LOADING }));
    let produtos = await PRODUTOS.get.all();
    setState((s) => ({ ...s, produtos, status: STATUS.IDLE }));
  }

  function gotoEditar(id){
    navigate(`/admin/produtos/editar/${id}`);
  }

  return (
    <S.Container>
      <FHSB>
        <WhiteTitle>Produtos Participantes</WhiteTitle>
        <AddBtn link="/admin/produtos/novo" />
      </FHSB>
      <HR />
      {status === STATUS.LOADING ? (
        <Loader />
      ) : (
        <ShadowBox>
          <table
            className="table table-sm table-striped"
            style={{ fontSize: 12 }}
          >
            <thead>
              <tr>
                <td style={{width: 20}}>ID</td>
                <td style={{textAlign: 'center', width: 20}}>Pontos</td>
                <td style={{textAlign: 'center',width: 20}}>Status</td>
                <td>Nome</td>
              </tr>
            </thead>
            <tbody>
              {produtos.length === 0 && (
                <tr>
                  <td colSpan={4}>Nenhum produto cadastrado</td>
                </tr>
              )}
              {produtos.map((produto) => {
                console.log("produto", produto);
                return (
                  <tr key={produto.id} className="pointer" onClick={()=>{gotoEditar(produto.id)}}>
                    <td>{produto.id}</td>
                    <td style={{textAlign: 'center'}}>{produto.points}</td>
                    <td style={{textAlign: 'center'}}>{produto.active ? <Badge color="success">Ativo</Badge> : <Badge color="danger">Inativo</Badge>}</td>
                    <td>{produto.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </ShadowBox>
      )}
    </S.Container>
  );
}

export default Produtos;
