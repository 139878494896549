import React, { useEffect, useState } from "react";

import * as S from "../styles";
import { STATUS } from "../../../../../utils/constants";
import { PRESCRIPTIONS } from "../../../../../database/API/Prescriptions";
import { Button, Label, Text, Title } from "../../../../../main-styles";
import Loader from "../../../../../components/Loader";
import { colors } from "../../../../../utils/colors";
import { toast } from "react-toastify";

function Prescricao({ id, close }) {
  const [state, setState] = useState({
    dados: [],
    status: STATUS.LOADING,
  });

  useEffect(() => {
    if (!id) return;
    init();
  }, [id]);

  async function init() {
    setState((s) => ({ ...s, status: STATUS.LOADING }));
    let dados = await PRESCRIPTIONS.get.byUUID(id);
    console.log(dados);
    setState((s) => ({ ...s, dados, status: STATUS.IDLE }));
  }

  async function approve() {
    if (status === STATUS.PROCESSING) return;
    if (!window.confirm("Tem certeza que deseja aprovar essa prescrição?"))
      return;
    setState((s) => ({ ...s, status: STATUS.PROCESSING }));
    try {
      let response = await PRESCRIPTIONS.set.review(id, true);
      toast.success("Prescrição aprovada com sucesso");
      close(id, "approved");
    } catch (error) {
      toast.error("Erro ao aprovar prescrição");
    }
    setState((s) => ({ ...s, status: STATUS.IDLE }));
  }

  async function decline() {
    if (status === STATUS.PROCESSING) return;
    if (!window.confirm("Tem certeza que deseja recusar essa prescrição?"))
      return;
    setState((s) => ({ ...s, status: STATUS.PROCESSING }));
    try {
      let response = await PRESCRIPTIONS.set.review(id, false);
      toast.success("Prescrição recusada com sucesso");
      close(id, "declined");
    } catch (error) {
      toast.error("Erro ao recusar prescrição");
    }
    setState((s) => ({ ...s, status: STATUS.IDLE }));
  }

  const { dados, status } = state;

  if (status === STATUS.LOADING) return <Loader />;
  let imagem = dados.image.replace("500x500", "750x750");
  return (
    <S.PrescriptionContainer>
      <S.PrescriptionInfo>
        <Title>Prescrição #{dados.id}</Title>
        <Text>Espécie: {dados.fase_da_vida}</Text>
        <Text>Fase da vida: {dados.especie}</Text>
        <Text>UUID: {dados.uuid}</Text>
      </S.PrescriptionInfo>
      {dados.status === "created" && (
        <S.PrescriptionPoints>
          <Button onClick={approve} color={colors.green}>
            Aprovar
          </Button>
          <Button onClick={decline} color={colors.red} style={{ marginTop: 2 }}>
            Recusar
          </Button>
        </S.PrescriptionPoints>
      )}
      <S.PrescriptionItems>
        <img src={imagem} />
      </S.PrescriptionItems>
    </S.PrescriptionContainer>
  );
}

export default Prescricao;
