import React, { useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import * as S from "./styles";
import { Users } from "../../database/API";
import { Link, Outlet } from "react-router-dom";
import ShadowBox from "../../components/ShadowBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBottleWater,
  faCheck,
  faGift,
  faSearch,
  faTicket,
  faUser,
} from "@fortawesome/pro-light-svg-icons";
import { FH, HR } from "../../main-styles";

function Admin() {
  useEffect(() => {});
  document.title = "Admin |  Orga&Vet";

  return (
    <S.Container>
      <Container>
        <Row>
          <Col md={2} style={{ paddingTop: 30, paddingBottom: 50 }}>
            <ShadowBox>
              <ItemLink name="Usuários" link="/admin/usuarios" icon={faUser} />
              {/* <ItemLink
                name="Conferência"
                link="/admin/conferencia"
                icon={faCheck}
              /> */}
              <ItemLink
                name="Resgates"
                link="/admin/resgates"
                icon={faTicket}
              />
              <HR />
              <ItemLink
                name="Produtos participantes"
                link="/admin/produtos"
                icon={faBottleWater}
              />
              <ItemLink name="Prêmios" link="/admin/premios" icon={faGift} />
            </ShadowBox>
          </Col>
          <Col md={10} style={{ paddingTop: 30, paddingBottom: 50 }}>
            <Outlet />
          </Col>
        </Row>
      </Container>
    </S.Container>
  );
}

function ItemLink({ name, link, icon }) {
  return (
    <Link to={link}>
      <FH style={{ lineHeight: 1, marginBottom: 10 }}>
        <div style={{ width: 30, minWidth: 30 }}>
          <FontAwesomeIcon icon={icon} style={{ marginRight: 5 }} />
        </div>
        <div style={{ fontSize: 13 }}>{name}</div>
      </FH>
    </Link>
  );
}

export default Admin;
