import { CallApi } from "../db";
import { userData } from "../helper";

export const TRANSACTIONS = {
    get: {
        byUID: async (uid) => {
            let response =await CallApi.get(`/balance/transactions/${uid}`);
            return response.data
        },
        byID: async(id,uid)=>{
            if(!uid){
                let user = await userData();
                console.log(user)
                uid = user.uid
            }
            let response =await CallApi.get(`/balance/transactions/${uid}/${id}`);
            return response.data
        }
    },
    set: {

    }
}