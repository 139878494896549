import React, { useEffect, useState } from "react";

import * as S from "./styles";
import { Button, HR, Input, TextArea, Title, WhiteTitle } from "../../../../main-styles";
import ShadowBox from "../../../../components/ShadowBox";
import { Label } from "reactstrap";
import { STATUS } from "../../../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoFilm } from "@fortawesome/pro-light-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PREMIOS } from "../../../../database/API/Premios";
import { IMAGES } from "../../../../database/API/Images";
import { uploadImage } from "../../../../utils/functions";
import Loader from "../../../../components/Loader";

function Novo() {
  const {id} = useParams();
  
  const navigate = useNavigate();

  const [state, setState] = useState({
    nome: "",
    descricao: "",
    points: 100,
    imagem: "",
    status: STATUS.LOADING,
    uploadLink: "",
    file: null,
    preview: false,
    editar: id ? true : false,
    old_url: "",
  });

  const { nome, descricao, points, imagem, status, editar, old_url} = state;

  document.title = "Prêmios | Admin |  Orga&Vet";

  useEffect(() => {
    if (id) {
      loadPrize();
    } else {
      setState((s) => ({ ...s, status: STATUS.IDLE }));
    }
  }, []);

  async function loadPrize() {
    console.log("loadPrize",id);
    let premio = await PREMIOS.get.one(id);
    console.log("premio", premio);
    const { name, description, image, points } = premio;
    setState((s) => ({
      ...s,
      nome: name,
      descricao: description,
      imagem: image,
      status: STATUS.IDLE,
      old_url: image,
      points
    }));
  }

  function updateField(field, value) {
    setState((s) => ({ ...s, [field]: value }));
  }

  function validate() {
    if (status !== STATUS.IDLE) return;
    if (nome === "") return toast.error("Preencha o nome");
    if (descricao === "" || descricao.length < 30)
      return toast.error("Preencha a descrição corretamente");
    if (points === "" || isNaN(points))
      return toast.error("Preencha os pontos");
    if (state.file === null && old_url === "") return toast.error("Selecione a imagem");
    if(editar){
      update();
    } else {

      save();
    }
  }

  async function getImageUploadLink(file) {
    setState((s) => ({ ...s, uploadLink: "" }));
    let uploadLink = await IMAGES.get.uploadLink(file.name, file.type);
    console.log("uploadLink", uploadLink);
    let re = await uploadImage(
      file,
      uploadLink.filename,
      uploadLink.filetype,
      uploadLink.signedRequest
    );
    return uploadLink.url;
  }

  async function openFileSelector() {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = async (e) => {
      let file = e.target.files[0];
      updateField("file", file);
      let ob = URL.createObjectURL(file);
      updateField("imagem", ob);

    };
    input.click();
  }



  async function save() {
    setState((s) => ({ ...s, status: STATUS.PENDING }));
    try {
      let url = await getImageUploadLink(state.file);
      let response = await PREMIOS.set.new(nome, url, descricao, points);
      toast.success("Prêmio cadastrado");
      navigate("/admin/premios");
    } catch (error) {
      toast.error("Erro ao cadastrar prêmio");
      setState((s) => ({ ...s, status: STATUS.IDLE }));
    }
  }

  async function update(){
    setState((s) => ({ ...s, status: STATUS.PENDING }));
    try {
      let url = state.file ? await getImageUploadLink(state.file) : old_url;
      await PREMIOS.set.edit(id,nome, url, descricao, points);
      toast.success("Prêmio editado");
      navigate("/admin/premios");
    } catch (error) {
      toast.error("Erro ao editar prêmio");
      setState((s) => ({ ...s, status: STATUS.IDLE }));
    }
  }


  if(status === STATUS.LOADING) return <Loader />

  return (
    <S.Container>
      <WhiteTitle>{editar ? "Editar Prêmio": "Novo Prêmio"}</WhiteTitle>
      <HR />
      <ShadowBox>
        <S.Grid>
          <S.NameContainer>
            <Label>Nome</Label>
            <Input
              value={nome}
              onChange={(e) => updateField("nome", e.target.value)}
              placeholder="Premiação"
            />
          </S.NameContainer>
          <S.DescriptionContainer>
            <Label>Descrição</Label>
            <TextArea
              value={descricao}
              onChange={(e) => updateField("descricao", e.target.value)}
            />
          </S.DescriptionContainer>
          <S.PointsContainer>
            <Label>Pontos</Label>
            <Input
              value={points}
              type={"number"}
              onChange={(e) => updateField("points", e.target.value)}
            />
          </S.PointsContainer>
          <S.ImageContainer>
            <Label>Imagem</Label>
            {imagem !== "" ? (
              <S.Image src={imagem} onClick={openFileSelector} />
            ) : (
              <S.ImagePlaceholder onClick={openFileSelector}>
                <FontAwesomeIcon icon={faPhotoFilm} />
              </S.ImagePlaceholder>
            )}
          </S.ImageContainer>
          <S.Bottom>
            <HR />
            <Button onClick={validate} disabled={status !== STATUS.IDLE}>
              Salvar
            </Button>
          </S.Bottom>
        </S.Grid>
      </ShadowBox>
    </S.Container>
  );
}

export default Novo;
