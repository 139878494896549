import styled from "styled-components";
import { colors } from "../../utils/colors";

export const Container = styled.div`
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
  border: 1px solid #ccc;
  border-radius: 15px;
  box-shadow: none;
  background-color: #fff;
  margin-top: -50px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  background-color: ${colors.lighter_main};
  flex-direction: column;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: start;
  background-position: 0% 0%;
  // align background to the left

  //media query for mobile
  @media (max-width: 500px) {
    background-size: auto 120% ;
    background-repeat: no-repeat;
    background-position: 85% 40%;
  }
`;

export const Ribbon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: -80px;
  background-color: ${colors.main};
  z-index: 9999;
  position: relative;
  padding: 10px;
  border-radius: 10px 10px 0px 0px;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 30px;
`;

export const RibbonImg = styled.img``;

export const SeekHelp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  color: ${colors.main};
  border: 4px dashed ${colors.main};
  background-color: #fbf2c8;
  // shadow
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.6);

  max-width: 300px;
  border-radius: 19px;
  padding: 20px;

  transform: rotate(2deg);
`;
