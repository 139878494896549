import React, { useEffect, useState } from "react";

import * as S from "./styles";
import { Col, Container, Modal, ModalBody, Row } from "reactstrap";
import { Description, HR, Title } from "../../main-styles";
import ShadowBox from "../../components/ShadowBox";
import { STATUS } from "../../utils/constants";
import { TRANSACTIONS } from "../../database/API/Transactions";
import { useSelector } from "react-redux";
import { colors } from "../../utils/colors";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faFileAlt } from "@fortawesome/pro-light-svg-icons";
import Detalhes from "./Detalhes";

function Extrato() {
  const { uid } = useSelector((state) => state.auth.userData);
  const [state, setState] = useState({
    extrato: [],
    status: STATUS.LOADING,
    selected: null,
  });
  const { extrato, status, selected } = state;

  useEffect(() => {
    init();
  }, []);

  async function init() {
    setState((s) => ({ ...s, status: STATUS.LOADING }));
    let extrato = await TRANSACTIONS.get.byUID(uid);
    setState((s) => ({ ...s, extrato, status: STATUS.IDLE }));
  }

  async function closeModal() {
    setState((s) => ({ ...s, selected: null }));
  }

  async function openModal(id) {
    setState((s) => ({ ...s, selected: id }));
  }

  return (
    <Container style={{ marginTop: 30 }}>
      <Row>
        <Col>
          <Title>Extrato</Title>
          <Description></Description>
          <HR />
          <ShadowBox>
            <table
              className="table table-stripped table-sm"
              style={{ fontSize: 12 }}
            >
              <thead>
                <tr>
                  <td style={{ width: 50 }}>Referência</td>
                  <td style={{ width: 120 }}>Pontos</td>
                  <td style={{ width: 120 }}>Data</td>
                  <td>Observações</td>
                </tr>
              </thead>
              <tbody>
                {extrato.length === 0 && (
                  <tr>
                    <td colSpan={4}>Nenhuma transação encontrada</td>
                  </tr>
                )}
                {extrato.map((item) => {
                  let ref = uid.substr(0, 4) + "0" + item.id;
                  let observations =
                    item.observations !== item.document
                      ? item.observations.substr(0, 120)
                      : "-";
                  return (
                    <LinhaExtrato
                      key={item.id}
                      linha={{ ...item, ref, observations }}
                      onClick={() => {
                        openModal(item.id);
                      }}
                    />
                  );
                })}
              </tbody>
            </table>
          </ShadowBox>
        </Col>
      </Row>
      <Modal isOpen={selected !== null} toggle={closeModal}>
        <ModalBody>
          <Detalhes id={selected} close={closeModal} />
        </ModalBody>
      </Modal>
    </Container>
  );
}

function LinhaExtrato({ linha, onClick = () => {} }) {

  const cor = linha.operation === "C" ? "green" : "red";
  const pre = linha.operation === "C" ? "+" : "";

  const light =
    linha.operation === "C" ? colors.lighter_green : colors.lighter_red;

  return (
    <tr
      className="pointer"
      style={{ backgroundColor: light }}
      onClick={onClick}
    >
      <td style={{ backgroundColor: light }}>{linha.ref}</td>
      <td style={{ color: cor, fontWeight: 600, backgroundColor: light }}>
        {pre}
       1 ponto
      </td>
      <td style={{ backgroundColor: light }}>
        {moment(linha.date).format("DD/MM/YYYY HH:mm:ss")}
      </td>
      <td style={{ backgroundColor: light }}>{linha.observations}</td>
    </tr>
  );
}

export default Extrato;
