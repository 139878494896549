import { faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { Title } from "../../main-styles";

function AddBtn({ link }) {
  return (
    <Link to={link}>
      <Title>
        <FontAwesomeIcon icon={faPlusCircle} />
      </Title>
    </Link>
  );
}

export default AddBtn;
