import React from "react";
import { Badge } from "reactstrap";

function UserStatusBadge({ status }) {
  switch (status) {
    case 2:
      return (
        <Badge
          color="success"
        >
          Ativo
        </Badge>
      );
    case 0:
      return (
        <Badge
          color="danger"
        >
          Excluído
        </Badge>
      );
    case 1:
      return (
        <Badge
          color="warning"
        >
          Bloqueado
        </Badge>
      );
    default:
      return (
        <Badge
          color="info"
        >
          Desconhecido
        </Badge>
      );
  }
}

export default UserStatusBadge;
