import React from 'react';

import * as S from './styles';

function AmountSelector({value=1, onChange=()=>{}}) {
    function up(){
        onChange(value + 1);
    }

    function down(){
        if(value > 1)
        onChange(value - 1);
    }
  return <S.Container>
    <S.Button onClick={down}>-</S.Button>
    <S.Number>{value}</S.Number>
    <S.Button onClick={up}>+</S.Button>
  </S.Container>;
}

export default AmountSelector;