import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Login from "./Login";
import { Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import firebase from "../database/firebaseConfig";
import { logout, restoreLogin, setAdmin } from "../redux/slices/auth/authSlice";
import Header from "../components/Header";
import { colors } from "../utils/colors";
import Admin from "./Admin";
import Users from "./Admin/Users";
import UserNovo from "./Admin/Users/Novo";
import UserDetalhes from "./Admin/Users/Detalhes";
import Resgates from "./Admin/Resgates";
import Produtos from "./Admin/Produtos";
import ProdutosParticipantes from "./Produtos";
import ProdutosNovo from "./Admin/Produtos/Novo";
import Premios from "./Admin/Premios";
import PremiosNovo from "./Admin/Premios/Novo";
import { USERS } from "../database/API/User";
import NovoEnvio from "./NovoEnvio";
import Extrato from "./Extrato";
import ChangePassword from "./ChangePassword";
import Resgatar from "./Resgatar";
import Dashboard from "./Admin/dashboard";
function R() {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const { authenticated, admin, shouldChangePassword } = auth;

  useEffect(() => {
    firebase.auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("user", user);
        loggedUser(user);
      } else {
        if (auth.authenticated) {
          dispatch(logout());
        }
      }
    });
  }, []);

  async function loggedUser(user) {
    const { accessToken, expirationTime, refreshToken } = user.stsTokenManager;
    let uid = user.uid;
    let data = await USERS.get.me(uid, accessToken);
    console.log("data", data);
    let userData = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      token: accessToken,
      refreshToken: refreshToken,
      expiration: expirationTime,
      admin: data.admin,
      pontos: data.balance,
    };
    dispatch(restoreLogin(userData));
  }
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: colors.light_blue,
      }}
    >
      <Router>
        <Container fluid>
          <Row>
            <Col md={12} style={{ backgroundColor: "#fff" }} className="p-0">
              <Header />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="p-0">
              {!authenticated ? (
                <Routes>
                  <Route path="*" element={<Navigate replace to="/login" />} />
                  <Route path="/login" element={<Login />} />
                  {/* <Route path="/cadastro" element={<Cadastro />} /> */}
                </Routes>
              ) : (
                <Routes>
                  {admin ? (
                    <Route
                      path="/"
                      element={<Navigate replace to="/admin" />}
                    />
                  ) : (
                    <Route
                      path="/"
                      element={<Navigate replace to="/progresso" />}
                    />
                  )}
                  {shouldChangePassword ? (
                    <>
                      <Route path="/login" element={<ChangePassword />} />
                      {/* // chatch all */}

                      <Route path="*" element={<ChangePassword />} />
                    </>
                  ) : (
                    <>
                      <Route
                        path="/login"
                        element={<Navigate replace to="/" />}
                      />
                      <Route path="/progresso" element={<Resgatar />} />
                      <Route path="/produtos" element={<ProdutosParticipantes />} />

                      <Route
                        path="/trocar-senha"
                        element={<ChangePassword />}
                      />
                      <Route path="/enviar" element={<NovoEnvio />} />
                      <Route path="/extrato" element={<Extrato />} />
                      {admin && (
                        <Route path="/admin" element={<Admin />}>
                          <Route path="/admin" element={<Dashboard />} />
                          <Route path="/admin/usuarios" element={<Users />} />
                          <Route
                            path="/admin/usuarios/novo"
                            element={<UserNovo />}
                          />
                          <Route
                            path="/admin/usuarios/edit/:uid"
                            element={<UserNovo />}
                          />
                          <Route
                            path="/admin/usuarios/detalhes/:uid"
                            element={<UserDetalhes />}
                          />
                          <Route
                            path="/admin/conferencia"
                            element={<Users />}
                          />
                          <Route
                            path="/admin/resgates"
                            element={<Resgates />}
                          />
                          <Route
                            path="/admin/produtos"
                            element={<Produtos />}
                          />
                          <Route
                            path="/admin/produtos/novo"
                            element={<ProdutosNovo />}
                          />
                          <Route
                            path="/admin/produtos/editar/:id"
                            element={<ProdutosNovo />}
                          />

                          <Route path="/admin/premios" element={<Premios />} />
                          <Route
                            path="/admin/premios/novo"
                            element={<PremiosNovo />}
                          />
                          <Route
                            path="/admin/premios/editar/:id"
                            element={<PremiosNovo />}
                          />
                        </Route>
                      )}
                    </>
                  )}
                </Routes>
              )}
            </Col>
          </Row>
        </Container>
      </Router>
    </div>
  );
}

export default R;
