import React, { useEffect, useState } from "react";

import * as S from "./styles";
import { PAGE_TITLE } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Label, Text } from "../../main-styles";
import { AUTH_STATUS, login, logout } from "../../redux/slices/auth/authSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faSpinner,
  faUser,
} from "@fortawesome/pro-light-svg-icons";
import { Modal, ModalBody } from "reactstrap";
import Recover from "./Recover";
import BG1 from "../../assets/images/bg1.png";
import RIBBON1 from "../../assets/images/ribbon1.png";

function Login() {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = React.useState(false);
  function close() {
    setShow(false);
  }

  function open() {
    setShow(true);
  }
  console.log("auth", auth);
  // set page title
  useEffect(() => {
    document.title = PAGE_TITLE + " | Login";
  }, []);

  async function doLogin(e) {
    e?.preventDefault();
    if (auth.status === AUTH_STATUS.PENDING) return;
    console.log("doLogin", email, password);
    dispatch(login({ email, password }));
  }

  let status = auth.status;
  let message = auth.message;
  let running = status === "pending";

  if (auth.authenticated) {
    return (
      <S.Container>
        <Button onClick={() => dispatch(logout())}>Sair</Button>
      </S.Container>
    );
  }

  return (
    <S.Wrapper
      className="animate__animated animate__fadeIn"
      style={{
        backgroundImage: `url(${BG1})`,
      }}
    >
      <form onSubmit={doLogin} className="animate__animated animate__bounceIn">
        {/* <S.Ribbon>
          <S.RibbonImg src={RIBBON1} alt="ribbon" style={{ height: 150 }} />
        </S.Ribbon> */}
        <S.Container>
          <Label>Email</Label>
          <Input
            type="email"
            value={email}
            disabled={running}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Label>Senha</Label>
          <Input
            type="password"
            value={password}
            disabled={running}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button onClick={doLogin}>
            {status === AUTH_STATUS.PENDING ? (
              <FontAwesomeIcon icon={faSpinner} spin={true} />
            ) : (
              "Entrar"
            )}
          </Button>

          {status === AUTH_STATUS.ERROR && (
            <div className="alert alert-warning animate__animated animated__bounceIn">
              {message}
            </div>
          )}
          <Text onClick={open} style={{ cursor: "pointer" }}>
            <FontAwesomeIcon
              icon={faQuestionCircle}
              style={{ marginRight: 8 }}
            />
            Esqueceu a senha?
          </Text>
        </S.Container>
      </form>
      <S.SeekHelp className="animate__animated animate__delay-2s	 animate__swing">
        Caso encontre algum problema para acessar, entre em contato com
        consultor técnico
      </S.SeekHelp>
      <Modal isOpen={show} toggle={close}>
        <ModalBody>
          <Recover start={email} close={close} />
        </ModalBody>
      </Modal>
    </S.Wrapper>
  );
}

export default Login;
