import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import BG1 from "../../assets/images/securitydog-orga.png";
import { Button, Input, Label } from "../../main-styles";
import { PAGE_TITLE } from "../../utils/constants";
import * as S from "./styles";
import {
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  getAuth,
} from "@firebase/auth";
import { useNavigate } from "react-router-dom";
import { setShouldChangePassword } from "../../redux/slices/auth/authSlice";

function ChangePassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state);
  const [cpassword, setCPassword] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [running, setRunning] = useState(false);
  console.log("auth", auth);
  // set page title
  useEffect(() => {
    document.title = PAGE_TITLE + " |  Orga&Vet";
  }, []);

  async function changePassword() {
    if (password !== password2) {
      toast.error("As senhas não conferem");
      return;
    }
    if (password.length < 6) {
      toast.error("A senha deve ter no mínimo 6 caracteres");
      return;
    }
    // checks if cpasword is correct

    const auth2 = getAuth();
    const user = auth2.currentUser;

    try {
      const credential = EmailAuthProvider.credential(
        auth.userData.email,
        cpassword
      );
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, password);
      console.log("reauthenticateWithCredential");
      toast.success("Senha alterada com sucesso");
      dispatch(setShouldChangePassword(false));
      navigate("/enviar");
    } catch (error) {
      console.log(error);
      console.log(error.message);
      toast.error("Senha atual incorreta");
    }

    setRunning(false);
  }

  return (
    <S.Wrapper
      className="animate__animated animate__fadeIn"
      style={{
        backgroundImage: `url(${BG1})`,
      }}
    >
      <form
        onSubmit={changePassword}
        className="animate__animated animate__bounceIn"
      >
        <S.Ribbon>Troque sua senha</S.Ribbon>
        <S.Container>
          <Label>Senha atual</Label>
          <Input
            type="password"
            value={cpassword}
            disabled={running}
            onChange={(e) => setCPassword(e.target.value)}
          />
          <Label>Nova senha</Label>
          <Input
            type="password"
            value={password}
            disabled={running}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Label>Confirmação</Label>
          <Input
            type="password"
            value={password2}
            disabled={running}
            onChange={(e) => setPassword2(e.target.value)}
          />
          <Button onClick={changePassword}>Salvar Senha</Button>
        </S.Container>
      </form>
    </S.Wrapper>
  );
}

export default ChangePassword;
