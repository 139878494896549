import React, { useEffect, useState } from "react";

import { Col, Container, Row } from "reactstrap";
import Loader from "../../components/Loader";
import ShadowBox from "../../components/ShadowBox";
import { PRODUTOS } from "../../database/API/Produtos";
import {
  BigNumber,
  HR,
  ProductGrid,
  Text,
  Title,
  WhiteTitle,
} from "../../main-styles";
import { STATUS } from "../../utils/constants";
import SmartImage from "../../components/SmartImage";

function Produtos() {
  const [state, setState] = useState({
    produtos: [],
    status: STATUS.LOADING,
    selected: null,
  });
  const { produtos, status, selected } = state;

  useEffect(() => {
    init();
  }, []);

  async function init() {
    setState((s) => ({ ...s, status: STATUS.LOADING }));
    let produtos = await PRODUTOS.get.all();
    setState((s) => ({ ...s, produtos, status: STATUS.IDLE }));
  }

  if (status === STATUS.LOADING) return <Loader />;

  return (
    <Container style={{ marginTop: 30 }}>
      <Row>
        <Col>
          <WhiteTitle>Produtos participantes</WhiteTitle>
          <HR />
          <ProductGrid>
            {produtos.map((produto) => {
              console.log("produto", produto);
              return (
                <ShadowBox
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Title>{produto.name}</Title>
                  <SmartImage src={produto.image} width={150} height={150} />
                  <BigNumber style={{ paddingBottom: 0, lineHeight: 0.8 }}>
                    {produto.points}
                  </BigNumber>
                  <Text>pontos</Text>
                  <HR />
                  <span>{produto.description}</span>
                </ShadowBox>
              );
            })}
          </ProductGrid>
        </Col>
      </Row>
    </Container>
  );
}

export default Produtos;
