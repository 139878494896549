import styled from 'styled-components';

export const Container = styled.div`
  
`;


export const PrescriptionContainer= styled.div`
display: grid;
grid-template-columns: repeat(5, 1fr);
grid-template-rows: repeat(2, auto);
grid-column-gap: 10px;
grid-row-gap: 10px;
`

export const PrescriptionInfo = styled.div`
grid-area: 1 / 1 / 2 / 4;
`

export const PrescriptionPoints = styled.div`
grid-area: 1 / 5 / 2 / 6;
`

export const PrescriptionItems = styled.div`
grid-area: 2 / 1 / 3 / 6;
display: flex;
justify-content: center;
`

export const PrescriptionActions = styled.div`
grid-area: 1 / 4 / 2 / 5;
`
