import moment from "moment-timezone";
import axios from "axios";
import querystring from "querystring";
import { refreshToken } from "../../redux/slices/auth/authSlice";
import { API_URL, DEV } from "../../utils/constants";

function getStore() {
  const {store} = require("../../redux/store");
  console.log("store", store);
  return store.getState();
}

function getDispatch() {
  const {store} = require("../../redux/store");
  return store.dispatch;
}

const Store = {
  get: {
    current: async () => {
      let store = getStore();
      return store;
    },
    codigoUsuario: async () => {
      let { auth } = getStore();
      let { userData } = auth;
      return userData.uid;
    },
    userData: async () => {
      let { auth } = getStore();
      let { userData } = auth;
      return userData;
    },
    token: async () => {
      let currentState = getStore();
      let dispatch = getDispatch();
      console.log("currentState.auth.token", currentState.auth);
      if (currentState.auth.userData.token === false) {
        console.log("sem token");
        return false;
      }
      let { token, refresh_token, expiration } = currentState.auth.userData;
      let fiveMinutes = 300;
      expiration = (expiration - fiveMinutes);
      if (moment().valueOf() > expiration) {
        //if (moment().valueOf()) {
        console.log(
          "expirado em:",
          moment(expiration).format("DD/MM/YYYY HH:mm:ss")
        );
      }
      if (moment().valueOf() > expiration) {
        let r = await dispatch(refreshToken(refresh_token));
        console.log("oi", r);
      } else {
        console.log(
          "TOKEN EXPIRA EM:",
          moment(expiration).format("DD/MM/YYYY HH:mm:ss")
        );
        return token;
      }
    },
    refresh_the_token: async () => {
      let currentState = getStore();
      let { token, refresh_token, expiration } = currentState.auth.token;
      expiration = expiration / 1000
      console.log("currentState.auth.token", currentState.auth.token);
      if (token === false) {
        console.log("sem token");
        return false;
      } else {
        console.log("com token", token);
      }
      let fiveMinutes = 300;
      expiration = (expiration - fiveMinutes) * 1000;
      let antigo = token;
      let refreshURL =
        "https://securetoken.googleapis.com/v1/token?key=AIzaSyCGaB-2AbRvmsW21PdnANcGlzOOo7tzU_A";
      let config = {
        url: refreshURL,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: querystring.stringify({
          grant_type: "refresh_token",
          refresh_token,
        }),
      };
      let r = await axios(config);
      let novotoken = r.data.access_token;
      console.log("novo token:", novotoken);
      let new_expires = moment()
        .add(r.data.expires_in * 1000, "milliseconds")
        .valueOf();
      return { ...r.data, new_expires };
    },
  },
};

export async function constructor(path = "/", method = "get", body, token) {
  if (!token) {
    token = await Store.get.token();
  }

  let url = API_URL + '/api' + path;
  let config = {
    method,
    url,
    data: body,
    headers: {
      "Content-Type": "application/json",
      authorization: `bearer ${token}`,
    },
  };
  return axios(config);
}

export const CallApi = {
  get: async (path, token) => {
    return constructor(path, "GET", undefined, token);
  },
  post: async (path, body, token) => {
    return constructor(path, "POST", body, token);
  },
  patch: async (path, body, token) => {
    return constructor(path, "PATCH", body, token);
  },
  delete: async (path, token) => {
    return constructor(path, "DELETE", token);
  },
};

export default Store;
