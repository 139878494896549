import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Number = styled.div`
    font-weight: bold;
    font-size: 20px;
    margin: 10px;
    width: 40px;
    text-align: center;
`;

export const Button = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #e8e8e8;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;