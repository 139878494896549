import { CallApi } from "../db";

export const IMAGES = {
  get: {
    uploadLink: async (filename, filetype) => {
      let response = await CallApi.post(`/prescriptions/upload-link`, {
        filename,
        filetype,
      });
      return response.data;
    },
  },
};
