import React, { useEffect, useState } from "react";

import * as S from "../styles";
import {
  Button,
  FHSB,
  HR,
  Input,
  Label,
  Subtitle,
  Title,
} from "../../../../main-styles";
import { useNavigate, useParams } from "react-router-dom";
import { STATUS } from "../../../../utils/constants";
import { toast } from "react-toastify";
import { validateEmail } from "../../../../utils/functions";
import { USERS } from "../../../../database/API/User";
import { Users } from "../../../../database/API";
import NovoVet from "./NovoVet";
import ShadowBox from "../../../../components/ShadowBox";

function Novo() {
  const navigate = useNavigate();
  // checks if param uid exists
  // if it does, it's an edit page
  const { uid } = useParams();
  const isEdit = uid ? true : false;
  const [state, setState] = useState({
    loading: true,
    nome: "",
    email: "",
    admin: undefined,
    status: STATUS.IDLE,
  });
  const { loading, nome, email, admin, status } = state;

  useEffect(() => {
    init();
    document.title = "Usuários | Admin |  Orga&Vet";
  }, []);

  async function init() {
    if (uid) {
      // load user data
    } else {
      setState((s) => ({ ...s, loading: false }));
    }
  }

  function handleChange(field, value) {
    setState((s) => ({ ...s, [field]: value }));
  }

  async function validate() {
    // valida se nome e email estão preenchidos
    if (nome === "" || email === "")
      return toast.error("Preencha todos os campos");
    if (nome.trim().indexOf(" ") === -1)
      return toast.error("Preencha o nome completo");

    // valida se email é válido
    if (validateEmail(email) === false) return toast.error("Email inválido");

    // valida se email já existe
    let isEmailAvailable = await USERS.set.isEmailAvailable(email);
    if (!isEmailAvailable) return toast.error("Este email já está cadastrado");
    if (isEdit) {
      save();
    } else {
      create();
    }
    console.log("validado!!!");
  }

  async function save() {}

  async function create() {
    try {
      setState((s) => ({ ...s, status: STATUS.PENDING }));
      let response = await Users.USERS.set.newAdmin(nome, email);
      console.log(response);
      toast.success("Usuário criado com sucesso!");
      navigate("/admin/usuarios");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setState((s) => ({ ...s, status: STATUS.IDLE }));
    }
  }

  if (loading) return <div>Carregando...</div>;

  if (admin === undefined) {
    return (
      <ShadowBox>
        <S.Container>
          <Title>Novo usuário</Title>
          <Subtitle>Selecione o tipo de usuário que deseja criar</Subtitle>
          <FHSB>
            <Button
              style={{ flex: 1, minHeight: 100 }}
              onClick={() => setState((s) => ({ ...s, admin: true }))}
            >
              Administrador
            </Button>
            <div style={{ width: 10 }}></div>
            <Button
              style={{ flex: 1, minHeight: 100 }}
              onClick={() => setState((s) => ({ ...s, admin: false }))}
            >
              Veterinário
            </Button>
          </FHSB>
        </S.Container>
      </ShadowBox>
    );
  }

  return (
    <ShadowBox>
      <S.Container>
        {isEdit ? <Title>Editar usuário</Title> : <Title>Novo usuário</Title>}
        {admin ? (
          <Subtitle>Administrador</Subtitle>
        ) : (
          <Subtitle>Veterinário</Subtitle>
        )}
        <HR />
        {admin ? (
          <form>
            <Label>Nome completo</Label>
            <Input
              style={{ width: "100%" }}
              value={nome}
              onChange={(e) => handleChange("nome", e.target.value)}
            />
            <Label>Email</Label>
            <Input
              style={{ width: "100%" }}
              value={email}
              onChange={(e) => handleChange("email", e.target.value)}
            />
            <Label>
              Senha: <b>mudesuasenha</b>
            </Label>
            <HR />
            {status === STATUS.PENDING ? (
              <Button disabled>Salvando...</Button>
            ) : (
              <Button onClick={validate}>Salvar</Button>
            )}
          </form>
        ) : (
          <NovoVet />
        )}
      </S.Container>
    </ShadowBox>
  );
}

export default Novo;
