import styled from "styled-components";

export const Container = styled.div``;

export const PhotoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
`;

export const Photo = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;