import styled from "styled-components";
import { colors } from "./utils/colors";

export const Container = styled.div``;

export const Button = styled.div`
  background-color: ${(props) =>
    props.disabled ? "#ccc" : props.color ? props.color : colors.main};
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  padding: 6px 12px;
  text-align: center;
  transition: all 0.15s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${(props) => (props.disabled ? "#ccc" : colors.yellow)};
    color: ${colors.main};
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }
`;

export const Text = styled.div`
  color: #383838;
  font-size: 13px;
  font-weight: 400;
`;

export const Label = styled(Text)`
  margin-top: 10px;
  font-size: 14px;
`;

export const Input = styled.input`
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: none;
  padding: 4px 12px;
`;

export const Select = styled.select`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 12px;
`;

export const TextArea = styled.textarea`
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: none;
  padding: 4px 12px;
`;

export const VG = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
`;

export const Title = styled(Text)`
  font-size: 18px;
  font-weight: 600;
  color: ${colors.main};
  font-family: "GothamBlack" !important;
`;

export const WhiteTitle = styled(Title)`
  color: #fff;
`;

export const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin-bottom: 50px;
`;

export const Subtitle = styled(Text)``;

export const Description = styled(Text)``;

export const Table = styled.div``;

export const TableHeader = styled.div``;

export const TableBody = styled.div``;

export const TableRow = styled.div``;

export const TableCell = styled.div``;

export const HR = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px dotted #e4e4e4;
  margin: 10px 0 20px 0;
`;

export const Option = styled.option``;

export const Link = styled.a``;

export const Alert = styled.div``;

export const AlertDanger = styled.div``;

export const AlertSuccess = styled.div``;

export const AlertWarning = styled.div``;

export const AlertInfo = styled.div``;

export const Badge = styled.div``;

export const BadgeDanger = styled.div``;

export const BadgeSuccess = styled.div``;

export const FH = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const FHSB = styled(FH)`
  justify-content: space-between;
`;

export const BigNumber = styled.div`
  font-size: 40px;
  font-weight: 600;
  color: ${colors.main};
  font-family: "GothamBlack" !important;
`;
