import React from "react";

import * as S from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import imgCachorro from "../../assets/images/dog.png";
import imgGato from "../../assets/images/cat2.png";
import imgSilvestre from "../../assets/images/porcupine2.png";

function MultiToggler({ options, selected, onChange }) {

  return (
    <S.Container>
      <S.Option
        isSelected={selected === 'cachorro'}
        onClick={() => {
          onChange('cachorro');
        }}
        background={imgCachorro}
      >
      <span>Cachorro</span>
      </S.Option>
      <S.Option
        isSelected={selected === 'gato'}
        onClick={() => {
          onChange('gato');
        }}
        background={imgGato}
      >
      <span>Gato</span>
      </S.Option>
      <S.Option
        isSelected={selected === 'silvestre'}
        onClick={() => {
          onChange('silvestre');
        }}
        background={imgSilvestre}
      >
      <span>Silvestre</span>
      </S.Option>
    </S.Container>
  );
}

export default MultiToggler;
