export const colors = {
  main: "#0069FA",
  light_main:   "#ddfff8",
  lighter_main: "#e8f4ff",
  green: "#B8E300",
  yellow: "#FACD06",
  red: "#FD44C4",
  blue: "#0069FA",
  purple: "#830AD1",
  light_green: "#ddfff8",
  light_yellow: "#fff8dd",
  light_red: "#ffe8e6",
  light_blue: "#3e8cf9",
  white: "#fff",
  black: "#000",
  lighter_green: "#f0fff9",
  lighter_yellow: "#fffdf0",
  lighter_red: "#fff3f2",
};
