export const PAGE_TITLE = `Orga&Vet`

export const DEV = process.env.NODE_ENV === 'development';

const REMOTE = true;
const LOCAL_URL = REMOTE ? "https://orga-vet-b08e44eef6db.herokuapp.com" : "http://localhost:3000"
export const API_URL = DEV ? LOCAL_URL : "https://orga-vet-b08e44eef6db.herokuapp.com";

export const STATUS = {
    IDLE: 'idle',
    PENDING: 'pending',
    SUCCESS: 'success',
    ERROR: 'error',
    LOADING: 'loading',
    LOADING_MORE: 'loading_more',
    PROCESSING: 'processing',
}