import React from "react";

import * as S from "./styles";

function ShadowBox({ children, style = {}, animation = false }) {
  return (
    <S.Container style={style} className={animation ? animation : null} >
      {children}
    </S.Container>
  );
}

export default ShadowBox;
