import React, { useEffect, useState } from "react";

import * as S from "./styles";
import { Col, Container, Row } from "reactstrap";
import { BigNumber, Description, FH, FHSB, HR, Title } from "../../main-styles";
import ShadowBox from "../../components/ShadowBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faLockOpen,
  faRefresh,
} from "@fortawesome/pro-light-svg-icons";
import { colors } from "../../utils/colors";
import Countup from "react-countup";
import GaugeComponent from "react-gauge-component";
import RESG1 from "../../assets/images/boarding3.png";
import RESG2 from "../../assets/images/hotel5.png";
import { useDispatch, useSelector } from "react-redux";
import { USERS } from "../../database/API/User";
import { RESGATES } from '../../database/API/Resgates'
import { addPontos, setPontos } from "../../redux/slices/auth/authSlice";
import ProgressBar from "@ramonak/react-progress-bar";

import ReactGA from "react-ga4";
import { PREMIOS } from "../../database/API/Premios";
import Loader from "../../components/Loader";

function Resgatar() {
  const { auth } = useSelector((state) => state);
  const { userData } = auth;
  const p = useSelector((state) => state.auth.userData.pontos);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    carregando: true,
    premios: [],
    pontos: p,
  });
  const { carregando, premios, pontos } = state;

  useEffect(() => {
    updatePontos();
    getPrizes();
    ReactGA.event("Visualizou progresso");
  }, []);

  useEffect(() => {
    console.log("Pontos atualizou:", pontos);
  }, [pontos]);

  async function updatePontos() {
    let data = await USERS.get.me();
    console.log("data[1]:", data);
    if (!data) return;
    let pontos = parseInt(data?.balance);
    console.log("pontos", pontos);
    setState((s) => ({ ...s, pontos }));
    dispatch(setPontos(pontos));
  }

  async function getPrizes() {
    let prizes = await PREMIOS.get.all();
    console.log("prizes", prizes);
    setState((s) => ({ ...s, carregando: false, premios: prizes }));
  }

  function confirmResgate(produto) {
    if (!window.confirm(`Deseja resgatar ${produto.name}?`)) return;
    resgatar(produto);
  }

  async function resgatar(produto) {
    let response =  await RESGATES.set.resgatar(produto.id);
    console.log("response", response);

  }

  return (
    <Container
      style={{ marginTop: 30 }}
      className={`animate__animated animate__fadeIn`}
    >
      <Row>
        <Col>
          <ShadowBox animation={`animate__animated animate__fadeInRight`}>
            <FHSB style={{ padding: 10, paddingTop: 0, paddingBottom: 0 }}>
              <div>
                <Description>Você tem</Description>
                <FH>
                  <BigNumber
                    style={{
                      fontSize: 30,
                      lineHeight: 1,
                      fontFamily: "GothamBlack",
                    }}
                  >
                    <span>{pontos} pontos</span>
                  </BigNumber>
                  <div style={{marginLeft: 5, color: "#ccc", marginBottom: -7}} onClick={updatePontos}>
                    <FontAwesomeIcon icon={faRefresh} />
                  </div>
                </FH>
              </div>
            </FHSB>
            {/* <div style={{ flex: 1, alignItems: "flex-end" }}>
              <ProgressBar
                completed={pontos}
                maxCompleted={960}
                bgColor={colors.main}
                customLabel=" "
              />
            </div> */}
          </ShadowBox>
          {carregando ? (
            <Loader />
          ) : (
            <>
              {premios.map((premio) => {
                return (
                  <BtnResgate
                    title={premio.name}
                    description={premio.description}
                    image={premio.image}
                    points={premio.points}
                    locked={pontos < premio.points}
                    disabled={pontos < premio.points}
                    onClick={() => {
                      confirmResgate(premio);
                    }}
                  />
                );
              })}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

function BtnResgate({
  title,
  description,
  image,
  onClick,
  points,
  disabled,
  locked,
  animation = "animate__fadeInUp",
}) {
  return (
    <S.Card className={`animate__animated ${animation}`} onClick={onClick}>
      <S.CardLeftSide
        image={image}
        disabled={disabled}
        style={{ backgroundImage: image }}
      />
      <S.CardRightSide>
        <FHSB style={{ alignItems: "flex-start" }}>
          <Title>{title}</Title>
          <FontAwesomeIcon
            icon={locked ? faLock : faLockOpen}
            color={locked ? colors.red : colors.green}
            style={{ marginTop: 5 }}
          />
        </FHSB>
        <BigNumber
          style={{ fontSize: 25, color: "#000", justifySelf: "flex-end" }}
        >
          <FH>
            <Countup start={0} end={points} duration={1} />{" "}
            <span style={{ marginLeft: 5 }}>pontos</span>
          </FH>
        </BigNumber>
        <Description>{description}</Description>
      </S.CardRightSide>
    </S.Card>
  );
}

export default Resgatar;
