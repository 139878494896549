import React from "react";
import * as S from "./styles";
import BG_SUCESSO from "../../assets/images/successbg.png";
import 'animate.css';

function Overlay({ children, style = {}, success = false }) {
  if (success === true) {
    return <S.SUCCESS style={style} background={BG_SUCESSO} className="animate__animated animate__fadeIn">{children}</S.SUCCESS>;
  }
  return <S.Container style={style}  className="animate__animated animate__bounceIn">{children}</S.Container>;
}

export default Overlay;
