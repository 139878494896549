import React, { useEffect, useState } from "react";

import * as S from "./styles";
import Loader from "../../components/Loader";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { PRESCRIPTIONS } from "../../database/API/Prescriptions";
import moment from "moment-timezone";
import ShadowBox from "../../components/ShadowBox";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Dashboard() {
  const [state, setState] = useState({
    precriptions_graph: [],
    loading: true,
    days: 15,
  });
  const { precriptions_graph, loading, days } = state;

  useEffect(() => {
    init();
  }, []);

  async function init() {
    let promises = [PRESCRIPTIONS.get.graph(days)];
    let [precriptions_graph] = await Promise.all(promises);

    // fill empty dates for graph
    let dates = [];
    let start = moment().subtract(days, "days");
    let end = moment();
    for (let m = moment(start); m.diff(end, "days") <= 0; m.add(1, "days")) {
      let found = precriptions_graph.find((d) =>
        moment(d.date).isSame(m, "day")
      );
      console.log("found", found);
      if (found) {
        dates.push({
          date: moment(m).format("DD/MM/YYYY"),
          prescricoes: found.prescricoes,
          usuarios: found.usuarios,
        });
      } else {
        dates.push({
          date: moment(m).format("DD/MM/YYYY"),
          prescricoes: 0,
          usuarios: 0,
        });
      }
    }

    precriptions_graph = dates;
    console.log(precriptions_graph);

    setState((s) => ({ ...s, loading: false, precriptions_graph }));
  }

  if (loading)
    return (
      <S.Container>
        <Loader />
      </S.Container>
    );

  return (
    <S.Container>
      <ShadowBox>
        <DailyLineGraph dados={precriptions_graph} />
      </ShadowBox>
    </S.Container>
  );
}

function DailyLineGraph({ dados }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Envio de Prescrições",
      },
    },
  };

  const labels = dados.map((d) => d.date);

  const data = {
    labels,
    datasets: [
      {
        label: "Prescrições",
        data: dados.map((d) => d.prescricoes),
        borderColor: "rgb(255, 197, 5)",
        backgroundColor: "rgba(255, 203, 99, 0.5)",
      },
      {
        label: "Usuários",
        data: dados.map((d) => d.usuarios),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return <Line options={options} data={data} />;
}

export default Dashboard;
