import { CallApi } from "../db";

export const PREMIOS = {
  get: {
    all: async () => {
      let response = await CallApi.get(`/prizes`);
      return response.data;
    },
    one: async (id) => {
      let response = await CallApi.get(`/prizes/${id}`);
      return response.data;

    },
  },
  set: {
    new: async (nome, imagem, descricao, pontos) => {
      let payload = {
        name: nome,
        description: descricao,
        image: imagem,
        points: pontos,
        active: true,
      };
      let response = await CallApi.post(`/prizes`, payload);
      return true
    }, 
    edit: async (id, nome, imagem, descricao, pontos) => {
      let payload = {
        name: nome,
        description: descricao,
        image: imagem,
        points: pontos,
        active: true,
      };
      let response = await CallApi.patch(`/prizes/${id}`, payload);
      return true
    }
  },
};
