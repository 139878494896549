import React, { useState } from "react";
import { Button, FH, Input, Label } from "../../main-styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

function Filter({ needle, onChange, go }) {
  function clear() {
    onChange("");
  }

  function change(e){
    onChange(e.target.value)
  }
  return (
    <FH style={{ width: "100%" }}>
      <Input
        value={needle}
        onChange={change}
        style={{ width: "100%", marginRight: 10 }}
        placeholder="Procurar..."
      />
      <Button onClick={go}>Procurar</Button>
      {needle && needle !== "" && (
        <Button onClick={clear}>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      )}
    </FH>
  );
}

export default Filter;
