import React, { useEffect, useState } from "react";
import Fuse from "fuse.js";
import * as S from "../../styles";
import { STATUS } from "../../../../../utils/constants";
import { Users } from "../../../../../database/API";
import Filter from "../../../../../components/Filter";
import {
  Subtitle,
  Title,
  Button,
  HR,
  Label,
  Input,
} from "../../../../../main-styles";
import { toast } from "react-toastify";
import { validateEmail } from "../../../../../utils/functions";
import { USERS } from "../../../../../database/API/User";
import { useNavigate } from "react-router-dom";
function NovoVet({ select }) {
  const navigate = useNavigate();
  const [state, setState] = useState({
    orgalovers: [],
    filtered: [],
    status: STATUS.LOADING,
    needle: "",
    selected: false,
    nome: "",
    email: "",
    id_vet: "",
  });
  const {
    orgalovers,
    filtered,
    status,
    needle,
    selected,
    nome,
    email,
    id_vet,
  } = state;

  useEffect(() => {
    getOrgaLovers();
    document.title = "Usuários | Admin |  Orga&Vet";
  }, []);

  async function getOrgaLovers() {
    if (status !== STATUS.LOADING) return;
    console.log("buscando orgalovers");
    let orgalovers = await Users.USERS.get.orgalovers();
    console.log("orgalovers", orgalovers);
    setState((s) => ({
      ...s,
      orgalovers,
      filtered: orgalovers.slice(0, 10),
      status: STATUS.IDLE,
    }));
  }

  useEffect(() => {
    if (needle === "")
      return setState((s) => ({ ...s, filtered: orgalovers.splice(0, 10) }));
    filter();
  }, [needle]);

  function filter() {
    const options = {
      includeScore: true,
      // Search in `author` and in `tags` array
      keys: ["name", "crmv", "cpf", "phone", "city", "state", "email"],
    };

    const fuse = new Fuse(orgalovers, options);
    console.log("procurando por", needle);
    let result = fuse.search(needle);
    console.log("result", result);
    result = result.map((item) => item.item);
    result = result.splice(0, 10);
    setState((s) => ({ ...s, filtered: result }));
  }

  function select(vet) {
    setState((s) => ({
      ...s,
      selected: true,
      nome: vet.name,
      email: vet.email,
      id_vet: vet.cod,
    }));
  }

  function handleChange(field, value) {
    setState((s) => ({ ...s, [field]: value }));
  }

  function validate() {
    if (status === STATUS.PENDING) return;
    if (nome === "" || email === "")
      return toast.error("Preencha todos os campos");
    if (nome.trim().indexOf(" ") === -1)
      return toast.error("Preencha o nome completo");
    if (validateEmail(email) === false) return toast.error("Email inválido");
    if (selected === false) return toast.error("Selecione um veterinário");
    save();
  }

  async function save() {
    try {
      setState((s) => ({ ...s, status: STATUS.PENDING }));
      let response = await USERS.set.newVet(nome, email, id_vet);
      toast.success("Veterinário cadastrado com sucesso!");
      navigate("/admin/usuarios");
    } catch (error) {
      toast.error(error.response.data.message);
      setState((s) => ({ ...s, status: STATUS.IDLE }));
    }
  }

  return (
    <S.Container>
      {selected ? (
        <>
          <Subtitle>Veterinário</Subtitle>
          <Label>Nome completo</Label>
          <Input
            style={{ width: "100%" }}
            value={nome}
            onChange={(e) => handleChange("nome", e.target.value)}
          />
          <Label>Email</Label>
          <Input
            style={{ width: "100%" }}
            value={email}
            onChange={(e) => handleChange("email", e.target.value)}
          />
          <Label>
            Senha: <b>mudesuasenha</b>
          </Label>
          <HR />
          {status === STATUS.PENDING ? (
            <Button disabled>Salvando...</Button>
          ) : (
            <Button onClick={validate}>Salvar</Button>
          )}
        </>
      ) : (
        <>
          <Subtitle>
            Os veterinários aqui listados são Veterinários Orgalovers
          </Subtitle>
          <Filter
            needle={needle}
            onChange={(e) => setState((s) => ({ ...s, needle: e }))}
            go={() => {}}
          />
          <Subtitle>
            Filtre por Nome, CRMV, CPF, Email, Cidade ou Estado
          </Subtitle>
          <HR />
          <table className="table table-stripped" style={{ fontSize: 12 }}>
            <thead>
              <tr>
                <th>Nome</th>
                <th>CRMV</th>
                <th>CPF</th>
                <th>Telefone</th>
                <th>Cidade</th>
                <th>Estado</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filtered.map((item) => {
                console.log("item", item);
                let { name, cod, city, cpf, crmv, phone, state, email } = item;
                return (
                  <tr>
                    <td
                      style={{
                        textTransform: "capitalize",
                        verticalAlign: "middle",
                      }}
                    >
                      {name?.toLowerCase()}
                    </td>
                    <td style={{ verticalAlign: "middle" }}>{crmv}</td>
                    <td style={{ verticalAlign: "middle" }}>{cpf}</td>
                    <td style={{ verticalAlign: "middle" }}>{phone}</td>
                    <td style={{ verticalAlign: "middle" }}>{city}</td>
                    <td style={{ verticalAlign: "middle" }}>{state}</td>
                    <td style={{ verticalAlign: "middle" }}>
                      <Button onClick={() => select(item)}>Selecionar</Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </S.Container>
  );
}

export default NovoVet;
