import { store } from "../../redux/store";

// gets stuff from the store
export const userData = () => {
  let state = store.getState();
  return state.auth.userData;
};
export const token = () => {
  let state = store.getState();
  return state.auth.userData.token;
};
