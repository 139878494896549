import React, { useEffect, useState } from "react";

import * as S from "../styles";
import { Description, FHSB, HR, Title } from "../../../main-styles";
import { STATUS } from "../../../utils/constants";
import Loader from "../../../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { TRANSACTIONS } from "../../../database/API/Transactions";
import { PRESCRIPTIONS } from "../../../database/API/Prescriptions";
import { RESGATES } from "../../../database/API/Resgates";
import DetalhesPrescricao from "./DetalhesPrescricao";

function Detalhes({ id, type, close }) {
  const [state, setState] = useState({
    status: STATUS.LOADING,
    data: {},
  });
  const { data, status } = state;

  useEffect(() => {
    init();
  }, [id]);

  async function init() {
    let data = await TRANSACTIONS.get.byID(id);
    let extradata;
    if (data.operation === "C") {
      extradata = await PRESCRIPTIONS.get.byUUID(data.document);
    } else {
      extradata = await RESGATES.get.byUUID(data.document);
    }
    data = { ...data, ...extradata };
    console.log("DATA COM EXTRADATA", data);
    setState((s) => ({ ...s, data, status: STATUS.IDLE }));
  }

  return (
    <S.Container>
      <FHSB>
        <Title>Detalhes da transação</Title>
        <div onClick={close} className="pointer">
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </FHSB>
      <Description></Description>
      <HR />
      {status === STATUS.LOADING ? (
        <Loader />
      ) : (
        <>
          {data.operation === "C" ? <DetalhesPrescricao data={data} /> : <></>}
        </>
      )}
    </S.Container>
  );
}

export default Detalhes;
