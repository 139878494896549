import { CallApi } from "../db";

export const RESGATES = {
  get: {
    all: async (limit = 50, page = 0) => {
      let response = await CallApi.get(
        `/withdraws/all?all=true&limit=${limit}&page=${page}`
      );
      return response.data;
    },
    pending: async (limit = 50, page = 0) => {
      let response = await CallApi.get(
        `/withdraws/all?limit=${limit}&page=${page}`
      );
      return response.data;
    },
    byUID: async (uid) => {
      let response = await CallApi.get(`/withdraws/by-uid/${uid}`);
      return response.data;
    },
    byID: async (id) => {
      // let response =await CallApi.get(`/resgates/by-id/${uuid}`);
      // return response.data
      let response = await CallApi.get(`/withdraws/one/${id}`);
      return response.data;
    },
    allImagesFromUser: async (uid) => {
      let response = await CallApi.get(`/withdraws/images/${uid}`);
      return response.data;
    },
  },
  set: {
    resgatar: async (id) => {
      let response = await CallApi.post(`/withdraws`, { prize: id });
      return response.data;
    },
    aprovar: async (id) => {
      let response = await CallApi.patch(`/withdraws/approve/${id}`);
      return response.data;
    },
    declinar: async (id, reason) => {
      let response = await CallApi.patch(`/withdraws/decline/${id}`, {
        reason,
      });
      return response.data;
    },
  },
};
