import React from "react";

import * as S from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function MultiToggler({ options, selected, onChange }) {
  return (
    <S.Container>
      {options.map((option, index) => {
        const { value, label, icon } = option;
        let isSelected = selected === value;
        return (
          <S.Option
            key={index}
            isSelected={isSelected}
            onClick={() => {
              onChange(value);
            }}
          >
            <S.OptionIcon>
              <FontAwesomeIcon icon={icon} />
            </S.OptionIcon>
            <S.OptionTitle>{label}</S.OptionTitle>
          </S.Option>
        );
      })}
    </S.Container>
  );
}

export default MultiToggler;
