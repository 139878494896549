import React, { useEffect, useState } from 'react';

import * as S from '../styles';
import { Button, FHSB, Title } from '../../../../main-styles';
import { STATUS } from '../../../../utils/constants';
import { RESGATES } from '../../../../database/API/Resgates';
import Loader from '../../../../components/Loader';
import { colors } from '../../../../utils/colors';

function Verificacao({
    id,
    aprovar,
    recusar
}) {

    const [state, setState] = useState({
        data: null,
        status: STATUS.LOADING,
        all: false
    });
    const {data, status, all} = state;

    useEffect(()=>{
        if(!id) return
        init();
    }
    ,[id]);

    async function init(){
        setState(s=>({...s, status: STATUS.LOADING}));
        let data = await RESGATES.get.byID(id);
        console.log(data)
        setState(s=>({...s, data, status: STATUS.IDLE}));
    }

    async function carregarTodas(){
        setState(s=>({...s, status: STATUS.LOADING}));
        let nd = await RESGATES.get.allImagesFromUser(data?.uid)
        setState(s=>{
            let newData = {...s.data, images: nd}
            return {...s, data: newData, status: STATUS.IDLE, all: true}
        });
    }



    if(status === STATUS.LOADING) return <Loader />

  return <S.Container>
    <Title>Verificação de Resgate</Title>
    {!all && <div className='alert alert-info'>Selecionamos 20 fotos aleatóriamente para verificação.</div>}
    <hr/>
    <FHSB>
        <Button color={colors.green} onClick={aprovar}>Aprovar</Button>
        {!all && <Button color={colors.warn} onClick={carregarTodas}>Carregar Todas as Imagens</Button>}
        <Button color={colors.red} onClick={recusar}>Reprovar</Button>
    </FHSB>
    <hr/>
    <S.PhotoContainer>
        {data?.images.map((image,index)=>{
            image = image.replace('500x500', '1000x1000')
            return <a href={image} target="_blank"><S.Photo key={index} src={image}/></a>
        })}
    </S.PhotoContainer>
  </S.Container>;
}

export default Verificacao;