import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Warning = styled.div`
  left: 0;
  width: 100%;
  background-color: #f44336;
  color: white;
  text-align: center;
  padding: 10px;
  z-index: 9999;
`;

const MadeForMobile = () => {
  const { admin } = useSelector((state) => state.auth.userData);
  // based on width
  const isMobile = window.innerWidth <= 750;
  if (admin) return null;
  if (!isMobile) {
    return (
      <Warning>
        Este site foi criado para dispositivos móveis.
        <br />
        Por favor, visualize-o em um dispositivo móvel para obter a melhor
        experiência.
      </Warning>
    );
  }

  return null;
};

export default MadeForMobile;
