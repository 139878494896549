// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDNUNTlNePkzCrRTh4PU7Lgjj0FOTKA3Pc",
  authDomain: "orga-vet.firebaseapp.com",
  projectId: "orga-vet",
  storageBucket: "orga-vet.appspot.com",
  messagingSenderId: "436693323249",
  appId: "1:436693323249:web:55014d7d7d67ff0240c850",
  measurementId: "G-K3TK2Y387C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export default  {
  app,
  analytics,
  auth
}

