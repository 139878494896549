import axios from "axios";

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const uploadImage = async (
  file,
  filename,
  filetype,
  s2uploadlink,
  handleProgress = (progress) => {
    console.log("progress", progress);
  }
) => {
  const [type] = file.type.split("/");
  if (!type || type !== "image") {
    throw new Error({
      type: "FILE_CHANGE_FAILURE",
      fileError: "You can only upload image files.",
    });
  }
  let uploadTask = await axios.put(s2uploadlink, file, {
    headers: {
      "Content-Type": file.type,
      "x-amz-acl": "public-read",
    },
    onUploadProgress: (progressEvent) => {
      console.log("progressEvent", progressEvent);
      let total = progressEvent.total;
      let loaded = progressEvent.loaded;
      let progress = (loaded / total) * 100;
      handleProgress(progress);
    },
  });
};
