import React from "react";

import * as S from "./styles";

function SmartImage({ src, width = 400, height = width, style = {} }) {
  //console.log("SOURCE[4]", src);
  let filename = src.split("/");
  filename = filename[filename.length - 1];
  if (src.indexOf("d1ksfsmoha27ly") > -1) {
    // é organnact
    return (
      <S.Image
        src={`https://d1ksfsmoha27ly.cloudfront.net/fit-in/${width}x${height}/orga-img-server/${filename}`}
        style={style}
      />
    );
  }
  if (src.indexOf("firebase") > -1) {
    return <S.Image src={src} style={style} />;
  }
  if (src.indexOf("fit-in") > -1) {
    try {
      let url = src.split("/fit-in/");
      let inicio = url[0];
      // //console.log("inicio", inicio);
      let fim = url[1];
      // //console.log("fim", fim);
      fim = fim.split("/");
      // //console.log("fim[3]", fim);
      // remove o primeiro elemento
      fim.shift();
      // //console.log("fim3", fim);
      fim = fim.join("/");
      let smart_url = encodeURI(`${inicio}/fit-in/${width}x${height}/${fim}`);
      //console.log("smart_url", smart_url);
      return <S.Image src={smart_url} style={style} width={width} height={height} />;
    } catch (error) {
      console.error("ERRO NO SMART IMAGE", src);
      return <div />;
    }
  }
  return <S.Image src={src} style={style} width={width} height={height} />;
}

export function getSmartImageURL(url, width = 400, height = width) {
  if (!url) return url;

  let filename = url.split("/");
  filename = filename[filename.length - 1];
  if (url.indexOf("d1ksfsmoha27ly") > -1) {
    // é organnact
    return encodeURI(
      `https://d1ksfsmoha27ly.cloudfront.net/fit-in/${width}x${height}/orga-img-server/${filename}`
    );
  } else {
    if (url.indexOf("firebase") > -1) {
      return url;
    }
    url = url.split("/fit-in/");
    let inicio = url[0];
    let fim = url[1];
    fim = fim.split("/");
    // remove o primeiro elemento
    fim.shift();
    fim = fim.join("/");
    let smart_url = `${inicio}/fit-in/${width}x${height}/${fim}`;
    return encodeURI(smart_url);
  }
}

export default SmartImage;
