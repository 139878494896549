import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { Button, Input, Text, Title, VG } from "../../../main-styles";
import firebase from "../../../database/firebaseConfig";
import { toast } from "react-toastify";
import { Alert } from "reactstrap";
const { auth } = firebase;
const STATUS = {
  IDLE: "idle",
  PENDING: "pending",
  SUCCESS: "success",
  ERROR: "error",
};

function Recover({ start = "", close = () => {} }) {
  const [email, setEmail] = useState(start);
  const [status, setStatus] = useState(STATUS.IDLE);

  async function enviar() {
    try {
      setStatus(STATUS.PENDING);
      await sendPasswordResetEmail(auth, email);
      setStatus(STATUS.SUCCESS);
      setTimeout(() => {
        close();
      }, 3000);
      toast("Verifique sua caixa de email", { type: "success" });
    } catch (error) {
      console.log(error);
      setStatus(STATUS.IDLE);
      switch (error.code) {
        case "auth/invalid-email":
          toast("Email inválido", { type: "error" });
          break;
        case "auth/user-not-found":
          toast("Usuário não encontrado", { type: "error" });
          break;
        default:
          toast("Erro ao enviar email", { type: "error" });
          break;
      }
    }
  }

  return (
    <VG>
      <Title>Recuperação de Senha</Title>
      {status === STATUS.ERROR && (
        <Alert color="danger">Erro ao enviar email</Alert>
      )}
      {status === STATUS.IDLE && (
        <>
          <Text>Informe seu email de acesso.</Text>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button onClick={enviar}>Enviar</Button>
        </>
      )}
      {status === STATUS.PENDING && <Text>Enviando email...</Text>}
      {status === STATUS.SUCCESS && (
        <>
          <Text>Email enviado com sucesso!</Text>
          <Text>Verifique sua caixa de email para redefinir sua senha</Text>
        </>
      )}
    </VG>
  );
}

export default Recover;
