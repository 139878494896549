import React from "react";

import * as S from "../../styles";
import { HR, Label, Text, Title } from "../../../../main-styles";
import moment from "moment-timezone";

function DetalhesPrescricao({ data }) {
  return (
    <S.Container>
      <Title>Prescrição</Title>
      <Text>ID: {data.uuid}</Text>
      <Text>Data de envio: {moment().format("DD/MM/YYYY HH:mm:ss")}</Text>
      <HR />
      <Title>Produtos: {data.items.length} </Title>
      <table>

      </table>
    </S.Container>
  );
}

function Produto({id}){
    
}

export default DetalhesPrescricao;
