import React from "react";

import { Button, FHSB } from "../../main-styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-light-svg-icons";

function Pagination({ page = 0, next, previous, hasmore }) {
  return (
    <FHSB>
      <Button disabled={page === 0} onClick={previous}>
        <FontAwesomeIcon icon={faAngleLeft} />
      </Button>
      <div style={{fontSize: 11}}>{page}</div>
      <Button disabled={!hasmore} onClick={next}>
        <FontAwesomeIcon icon={faAngleRight} />
      </Button>
    </FHSB>
  );
}

export default Pagination;
