import React from "react";

import * as S from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { NavLink as NL } from "react-router-dom";
import { logout } from "../../redux/slices/auth/authSlice";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  Row,
} from "reactstrap";
import { faCog, faSignOut } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LOGO from "../../assets/images/logo.png";
import { colors } from "../../utils/colors";

function Header() {
  const { auth } = useSelector((state) => state);
  const { authenticated, admin } = auth;

  const dispatch = useDispatch();
  function sair() {
    console.log("sair");
    dispatch(logout());
  }

  return (
    <Navbar
      color="#fff"
      light
      expand="sm"
      full
      fill
      fixed
      style={{
        width: "100%",
        backgroundColor: "#fff",
        borderBottomColor: colors.main,
        borderBottomWidth: 5,
        borderBottomStyle: "solid",
        boxShadow: " 0px 0px 10px 0px rgba(0, 0, 0, 0.5)",
      }}
    >
      <NavbarBrand href="/">
        <S.Logo src={LOGO} />
      </NavbarBrand>
      {authenticated ? (
        <Nav>
          {admin ? (
            <NavItem>
              <NavLink tag={NL} to="/admin">
                Administração
              </NavLink>
            </NavItem>
          ) : (
            <>
            <NavItem>
              <NavLink tag={NL} to="/enviar">
                Nova prescrição
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={NL} to="/produtos">
                Produtos
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={NL} to="/progresso">
                Resgate
              </NavLink>
            </NavItem>
            </>
          )}

          <NavItem>
            <NavLink tag={NL} to="/trocar-senha">
              <FontAwesomeIcon icon={faCog} />
            </NavLink>
          </NavItem>
          <NavItem onClick={sair} style={{ cursor: "pointer" }}>
            <NavLink><FontAwesomeIcon icon={faSignOut} /></NavLink>
          </NavItem>
        </Nav>
      ) : (
        <Nav horizontal>
          
          <NavItem>
            <NavLink tag={NL} to="/login">
              Entrar
            </NavLink>
          </NavItem>
        </Nav>
      )}
    </Navbar>
  );
}

export default Header;
