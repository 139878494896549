import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px rgba(71, 71, 71, 0.6);
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
