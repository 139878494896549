import React, { useEffect, useState } from "react";

import * as S from "./styles";
import { HR, Title, WhiteTitle } from "../../../main-styles";
import { STATUS } from "../../../utils/constants";
import { RESGATES } from "../../../database/API/Resgates";
import Loader from "../../../components/Loader";
import ShadowBox from "../../../components/ShadowBox";
import { Modal, ModalBody } from "reactstrap";
import Verificacao from "./Verificacao";
import { toast } from "react-toastify";
import { PREMIOS } from "../../../database/API/Premios";
import { useNavigate } from "react-router-dom";

function Resgates() {
  const navigate = useNavigate();
  const [state, setState] = useState({
    resgates: [],
    premios: [],
    status: STATUS.LOADING,
    selected: null,
  });
  const { resgates, status, selected } = state;

  useEffect(() => {
    init();
    document.title = "Resgates Pendentes | Admin |  Orga&Vet";
  }, []);

  async function init() {
    setState((s) => ({ ...s, status: STATUS.LOADING }));
    let resgates = await RESGATES.get.pending();
    let premios = await pegaPremios();
    console.log("premios", premios);
    console.log("resgates", resgates);
    setState((s) => ({ ...s, resgates, status: STATUS.IDLE, premios }));
  }

  async function pegaPremios() {
    let premios = await PREMIOS.get.all();
    return premios;
  }

  function select(id) {
    setState((s) => ({ ...s, selected: id }));
  }

  function close() {
    select(null);
  }

  async function aprovar() {
    setState((s) => ({ ...s, status: STATUS.PROCESSING }));
    RESGATES.set.aprovar(selected);
    try {
      let response = await RESGATES.set.aprovar(selected);
      toast.success("Resgate aprovado com sucesso");
      setState((s) => ({
        ...s,
        resgates: resgates.filter((r) => r.id !== selected),
        selected: null,
      }));
    } catch (error) {
      toast.error("Erro ao aprovar resgate");
    }
    setState((s) => ({ ...s, status: STATUS.IDLE }));
  }

  async function recusar() {
    // eslint-disable-next-line no-restricted-globals
    let response = await confirm(`Atenção,
    o sistema não enviará nenhum email ao usuário, você deverá entrar em contato com ele para informar o motivo da recusa.
    
    Tem certeza que deseja recusar este resgate?`);
    if (!response) return;
    setState((s) => ({ ...s, status: STATUS.PROCESSING }));
    try {
      let response = await RESGATES.set.declinar(
        selected,
        "Conferencia manual"
      );
      setState((s) => ({
        ...s,
        resgates: resgates.filter((r) => r.id !== selected),
        selected: null,
      }));
      toast.success("Resgate recusado com sucesso");
    } catch (error) {
      toast.error("Erro ao recusar resgate");
    }
    setState((s) => ({ ...s, status: STATUS.IDLE }));
  }

  function gotoUser(uid) {
    navigate(`/admin/usuarios/detalhes/${uid}`);
  }

  function gotoPremio(id) {}

  const isOpen = selected !== null;

  return (
    <S.Container>
      <WhiteTitle>Resgates pendentes</WhiteTitle>
      <HR />
      {status === STATUS.LOADING ? (
        <Loader />
      ) : (
        <ShadowBox>
          <table
            className="table table-sm table-striped"
            style={{ fontSize: 12 }}
          >
            <thead>
              <tr>
                <td>ID</td>
                <td>Prêmio</td>
                <td>IDTP</td>
                <td>Nome</td>
              </tr>
            </thead>
            <tbody>
              {resgates.length === 0 && (
                <tr>
                  <td colSpan={4}>Nenhum resgate pendente</td>
                </tr>
              )}
              {resgates.map((resgate) => {
                let premio = state.premios.find(
                  (p) => parseInt(p.id) === parseInt(resgate.prize)
                );
                return (
                  <tr key={resgate.id} className="pointer">
                    <td onClick={() => select(resgate.id)}>{resgate.id}</td>
                    <td onClick={() => select(resgate.id)}>
                      {premio ? premio.name : "Prêmio não encontrado"}
                    </td>
                    <td onClick={() => select(resgate.id)}>{resgate.id_vet}</td>
                    <td onClick={() => gotoUser(resgate.uid)}>
                      {resgate.vet_name}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </ShadowBox>
      )}
      <Modal isOpen={isOpen} toggle={close} size="lg">
        <ModalBody>
          <Verificacao id={selected} aprovar={aprovar} recusar={recusar} />
        </ModalBody>
      </Modal>
    </S.Container>
  );
}

export default Resgates;
