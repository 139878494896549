import React, { useEffect, useMemo, useState } from "react";

import {
  faCamera,
  faCat,
  faDog,
  faMinusCircle,
  faSnake,
  faSpinner,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import { Col, Container, Row } from "reactstrap";
import AmountSelector from "../../components/AmountSelector";
import Loader from "../../components/Loader";
import MultiToggler from "../../components/MultiToggler";
import AnimalToggler from "../../components/AnimalToggler";
import Overlay from "../../components/Overlay";
import ShadowBox from "../../components/ShadowBox";
import SmartImage from "../../components/SmartImage";
import { IMAGES } from "../../database/API/Images";
import { PRESCRIPTIONS } from "../../database/API/Prescriptions";
import { PRODUTOS } from "../../database/API/Produtos";
import { Button, Description, HR, Label, Text, Title } from "../../main-styles";
import { colors } from "../../utils/colors";
import { STATUS } from "../../utils/constants";
import { uploadImage } from "../../utils/functions";
import NOVO_ENVIO_BG from "../../assets/images/prescriptionbg.png";
import * as S from "./styles";
import { useDispatch } from "react-redux";
import { addPontos } from "../../redux/slices/auth/authSlice";
import ReactGA from "react-ga4";
const IMAGE_SIZE = 90;

function NovoEnvio() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    status: STATUS.LOADING,
    produtos: [],
    selecionados: [],
    imagem: null,
    file: null,
    generatedPoints: 0,
    especie: null,
    fase: null,
  });
  const { status, produtos, selecionados, imagem, file, especie, fase } = state;
  useEffect(() => {
    document.title = "Novo envio de prescrição | Orga&Vet";
    init();
  }, []);

  function scrollBottom() {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  }

  async function init() {
    setState((s) => ({ ...s, status: STATUS.LOADING }));
    let produtos = await PRODUTOS.get.all();
    console.log(produtos);
    setState((s) => ({ ...s, produtos, status: STATUS.IDLE }));
  }

  function updateField(field, value) {
    setState((s) => ({ ...s, [field]: value }));
  }

  async function getImageUploadLink(file) {
    setState((s) => ({ ...s, uploadLink: "" }));
    let uploadLink = await IMAGES.get.uploadLink(file.name, file.type);
    console.log("uploadLink", uploadLink);
    await uploadImage(
      file,
      uploadLink.filename,
      uploadLink.filetype,
      uploadLink.signedRequest
    );
    return uploadLink.url;
  }

  async function openFileSelector() {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = async (e) => {
      let file = e.target.files[0];
      updateField("file", file);
      let ob = URL.createObjectURL(file);

      updateField("imagem", ob);
    };
    input.click();
  }

  function selectProduct(id) {
    setState((s) => {
      let selecionados = [...s.selecionados];
      selecionados.push({ id, amount: 1 });
      return { ...s, selecionados };
    });
  }

  function deselectProduct(id) {
    setState((s) => {
      let selecionados = [...s.selecionados];
      selecionados = selecionados.filter((a) => a.id !== id);
      return { ...s, selecionados };
    });
  }

  function setProductAmount(id, amount) {
    setState((s) => {
      let selecionados = [...s.selecionados];
      let index = selecionados.findIndex((a) => a.id === id);
      selecionados[index].amount = amount;
      return { ...s, selecionados };
    });
  }

  function validate() {
    if (imagem === null) {
      return toast.error("Tire uma foto da prescrição");
    }
    if (selecionados.length === 0) {
      return toast.error("Selecione pelo menos um produto");
    }
    if (especie === null) {
      return toast.error("Selecione a espécie");
    }
    if (fase === null) {
      return toast.error("Selecione a fase");
    }

    enviar();
  }

  async function enviar() {
    try {
      setState((s) => ({ ...s, status: STATUS.PENDING }));
      let image = await getImageUploadLink(file);
      console.log("image", image);
      let data = {
        image,
        products: selecionados.map((a) => ({ id: a.id, qty: a.amount })),
        especie,
        fase,
      };
      console.log("data", data);
      let response = await PRESCRIPTIONS.set.new(data);
      console.log("response", response);
      setState((s) => ({
        ...s,
        status: STATUS.SUCCESS,
        generatedPoints: response.points,
      }));
      dispatch(addPontos(response.points));
      ReactGA.event("Enviou prescrição", data);
    } catch (error) {
      console.error(error);
      toast.error("Erro ao enviar prescrição");
      setState((s) => ({ ...s, status: STATUS.IDLE }));
    }
  }
  useEffect(() => {
    if (selecionados.length > 0) {
      scrollBottom();
    }
    if (especie !== null && fase !== null && selecionados.length === 0) {
      scrollBottom();
    }
    if (imagem !== null) {
      scrollBottom();
    }
  }, [selecionados, especie, fase, imagem]);

  const AVAILABLE_OPTIONS = useMemo(() => {
    let options = produtos
      .filter((a) => !selecionados.some((b) => b.id === a.id))
      .map((produto) => {
        return {
          value: produto.id,
          label: produto.name,
          image: produto.image,
        };
      });
    return options;
  }, [produtos, selecionados]);

  return (
    <Container className="animate__animated animate__fadeIn">
      <Row>
        <Col>
          <S.Container background={NOVO_ENVIO_BG}>
            {status === STATUS.LOADING ? (
              <ShadowBox>
                <Loader />
              </ShadowBox>
            ) : (
              <>
                <ShadowBox animation="animate__animated animate__fadeInUp">
                  <Title>Dados do animal</Title>
                  <Label>Fase da Vida</Label>
                  <MultiToggler
                    onChange={(e) => updateField("fase", e)}
                    selected={fase}
                    options={[
                      { label: "Filhote", value: "filhote" },
                      { label: "Adulto", value: "adulto" },
                      { label: "Sênior", value: "senior" },
                    ]}
                  />
                  <Label>Espécie</Label>
                  <AnimalToggler
                    onChange={(e) => updateField("especie", e)}
                    selected={especie}
                    options={[]}
                  />
                </ShadowBox>
                <ShadowBox
                  style={{ marginTop: 10 }}
                  animation="animate__animated animate__fadeInUp"
                >
                  <Title>Produtos</Title>
                  {selecionados.map((p) => {
                    let produto = produtos.find((a) => a.id === p.id);
                    return (
                      <S.Produto>
                        <S.Remove onClick={() => deselectProduct(produto.id)}>
                          <FontAwesomeIcon icon={faMinusCircle} color={"red"} />
                        </S.Remove>
                        <SmartImage
                          height={IMAGE_SIZE}
                          width={IMAGE_SIZE}
                          src={produto.image}
                        />
                        <S.ProductInfo>
                          <span
                            style={{
                              fontWeight: 600,
                              fontSize: 18,
                              color: "#7e7e7e",
                            }}
                          >
                            {produto.name}
                          </span>
                          <Description>Quantidade</Description>
                          <AmountSelector
                            value={p.amount}
                            onChange={(e) => setProductAmount(p.id, e)}
                          />
                        </S.ProductInfo>
                      </S.Produto>
                    );
                  })}
                  <Select
                    className="basic-single"
                    onChange={(e) => selectProduct(e.value)}
                    value={null}
                    classNamePrefix="select"
                    placeholder="Selecione os produtos da prescrição"
                    isDisabled={false}
                    isSearchable={true}
                    name="color"
                    menuPlacement="auto"
                    options={AVAILABLE_OPTIONS}
                    formatOptionLabel={(produto) => {
                      console.log("produtooo", produto);
                      return (
                        <div>
                          <SmartImage
                            height={IMAGE_SIZE}
                            width={IMAGE_SIZE}
                            src={produto.image}
                          />
                          <span
                            style={{
                              fontWeight: 600,
                              fontSize: 18,
                              color: "#7e7e7e",
                            }}
                          >
                            {produto.label}
                          </span>
                        </div>
                      );
                    }}
                  />
                </ShadowBox>
                <ShadowBox style={{ marginTop: 10 }}>
                  <Title>Foto da Prescrição</Title>
                  <Text>Tire uma foto nítida e em local iluminado</Text>
                  {imagem === null ? (
                    <S.ImageSelector onClick={openFileSelector}>
                      <span style={{ fontSize: 40 }}>
                        <FontAwesomeIcon icon={faCamera} />
                      </span>
                      Tirar foto
                    </S.ImageSelector>
                  ) : (
                    <S.ImageSelector onClick={openFileSelector}>
                      <SmartImage
                        src={imagem}
                        height={200}
                        width={200}
                        style={{ borderRadius: 10, margin: 10 }}
                      />
                    </S.ImageSelector>
                  )}
                </ShadowBox>
                <Button
                  style={{
                    height: 60,
                    fontWeight: 600,
                    fontSize: 20,
                    marginTop: 10,
                    marginBottom: 50,
                  }}
                  onClick={validate}
                  disabled={status === STATUS.PENDING}
                >
                  Enviar
                </Button>
              </>
            )}
          </S.Container>
        </Col>
      </Row>
      {status === STATUS.PENDING && (
        <Overlay>
          <FontAwesomeIcon icon={faSpinner} spin size={"3x"} />
          <span>Enviando...</span>
        </Overlay>
      )}
      {status === STATUS.SUCCESS && (
        <Overlay success={true}>
          <img
            src={require("../../assets/images/maisumponto.png")}
            style={{ marginBottom: 30, maxWidth: 200, maxHeight: 200 }}
            className="animate__animated animate__bounceIn"
          />
          <Button
            style={{ backgroundColor: colors.main, width: "80%" }}
            onClick={() => navigate("/progresso")}
            className="animate__animated animate__fadeInUp"
          >
            FECHAR
          </Button>
        </Overlay>
      )}
    </Container>
  );
}

export default NovoEnvio;
