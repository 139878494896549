import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  overflow: hidden;
  color: #fff;
  flex-direction: column;
`;

export const SUCCESS = styled(Container)`
 background-image: url(${(props) => props.background});
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
 
`;
