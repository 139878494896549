import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const Logo = styled.img`
    width: 230px;
    height: 80px;
    object-fit: contain;
`;