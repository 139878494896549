import React, { useEffect, useState } from "react";

import * as S from "../styles";
import { useParams } from "react-router-dom";
import {
  BigNumber,
  FH,
  FHSB,
  HR,
  Label,
  Text,
  Title,
  WhiteTitle,
} from "../../../../main-styles";
import { STATUS } from "../../../../utils/constants";
import Loader from "../../../../components/Loader";
import { Users } from "../../../../database/API";
import moment from "moment-timezone";
import ShadowBox from "../../../../components/ShadowBox";
import UserStatusBadge from "../../../../components/UserStatusBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faEye,
  faFile,
  faFileAlt,
  faFileArchive,
} from "@fortawesome/pro-light-svg-icons";
import { PRESCRIPTIONS } from "../../../../database/API/Prescriptions";
import PrescriptionStatusBadge from "../../../../components/PrescriptionStatusBadge";
import { TRANSACTIONS } from "../../../../database/API/Transactions";
import { colors } from "../../../../utils/colors";
import { RESGATES } from "../../../../database/API/Resgates";
import { Badge, Modal, ModalBody } from "reactstrap";
import Prescricao from "./Prescricao";

function Detalhes() {
  const { uid } = useParams();

  const [state, setState] = useState({
    dados: [],
    prescricoes: [],
    resgates: [],
    status: STATUS.LOADING,
    extrato: [],
    selected_prescription: null,
  });
  const {
    dados,
    status,
    prescricoes,
    extrato,
    resgates,
    selected_prescription,
  } = state;

  useEffect(() => {
    init();
  }, [uid]);

  async function init() {
    const promises = [
      Users.USERS.get.one(uid),
      PRESCRIPTIONS.get.byUID(uid),
      TRANSACTIONS.get.byUID(uid),
      RESGATES.get.byUID(uid),
    ];
    let [dados, prescricoes, extrato, resgates] = await Promise.all(promises);
    setState((s) => ({
      ...s,
      dados,
      status: STATUS.IDLE,
      prescricoes,
      extrato,
      resgates,
    }));
  }

  function selectPrescription(id) {
    setState((s) => ({ ...s, selected_prescription: id }));
  }

  function closePrescription(id, status) {
    if (status) {
      setState((s) => {
        let prescricoes = s.prescricoes.map((p) => {
          if (p.uuid === id) {
            return { ...p, status };
          }
          return p;
        });
        return { ...s, prescricoes };
      });
    }
    setState((s) => ({ ...s, selected_prescription: null }));
  }

  if (!uid) return null;

  return (
    <>
      <FHSB>
        <WhiteTitle>Perfil</WhiteTitle>
      </FHSB>
      <HR />
      {status === STATUS.LOADING ? (
        <Loader />
      ) : (
        <S.GridContainer>
          <DataViewer data={dados} />
          <Points value={dados.balance} />
          <Prescriptions value={dados.prescriptions} />
          <PrescriptionList data={prescricoes} select={selectPrescription} />
          <RedeemList data={resgates} />
          <Extrato data={extrato} select={selectPrescription} />
        </S.GridContainer>
      )}
      <Modal
        size={"lg"}
        isOpen={selected_prescription !== null}
        toggle={closePrescription}
      >
        <ModalBody>
          <Prescricao id={selected_prescription} close={closePrescription} />
        </ModalBody>
      </Modal>
    </>
  );
}

/*



admin
created_at
email
id_vet
last_login
last_name
name
status
uid
*/
function DataViewer({ data }) {
  const { name, email, id_vet, last_login, last_name, status } = data;
  return (
    <S.BasicContainer>
      <ShadowBox>
        <Label>Nome</Label>
        <Text style={{ textTransform: "capitalize", fontWeight: 600 }}>
          {name} {last_name}
        </Text>
        <Label>Email</Label>
        <Text>{email}</Text>
        <Label>Último login</Label>
        <Text>
          {last_login
            ? moment(last_login).format("DD/MM/YYYY HH:mm:ss")
            : "Nunca acessou"}
        </Text>
        <HR />
        <FH>
          <UserStatusBadge status={status} />
        </FH>
      </ShadowBox>
    </S.BasicContainer>
  );
}

function Points({ value }) {
  return (
    <ShadowBox
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <S.PointsContainer>
        <BigNumber>{value}</BigNumber>
        <Text>pontos</Text>
      </S.PointsContainer>
    </ShadowBox>
  );
}
function Prescriptions({ value }) {
  return (
    <ShadowBox
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <S.BalanceContainer>
        <BigNumber>{value}</BigNumber>
        <Text>Prescrições</Text>
      </S.BalanceContainer>
    </ShadowBox>
  );
}

function PrescriptionList({ data, select }) {
  return (
    <S.PrescriptionsContainer>
      <ShadowBox>
        <Title>Prescrições</Title>
        <HR />
        <table
          className="table table-striped table-sm"
          style={{ fontSize: 13 }}
        >
          <thead>
            <tr>
              <td></td>
              <td>ID</td>
              <td>Status</td>
              <td>Data</td>
              <td style={{ textAlign: "center" }}>Pontos</td>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 && (
              <tr>
                <td colSpan={5}>Nenhuma prescrição</td>
              </tr>
            )}
            {data.map((linha) => {
              return (
                <tr
                  key={linha.id}
                  className="pointer"
                  onClick={() => select(linha.uuid)}
                >
                  <td style={{ textAlign: "center" }}>
                    <FontAwesomeIcon icon={faFileArchive} />
                  </td>
                  <td>{linha.uuid}</td>
                  <td>
                    <PrescriptionStatusBadge status={linha.status} />
                  </td>
                  <td>{moment(linha.created_at).format("DD/MM/YYYY")}</td>
                  <td style={{ textAlign: "center" }}>{linha.points}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </ShadowBox>
    </S.PrescriptionsContainer>
  );
}

function RedeemList({ data }) {
  function renderStatus(status) {
    switch (status) {
      case 0:
        // declinado
        return <Badge color="danger">Declinado</Badge>;
      case 1:
        // pendente
        return <Badge color="warning">Pendente</Badge>;
      case 2:
        // aprovado
        return <Badge color="success">Aprovado</Badge>;
      default:
        return null;
    }
  }
  return (
    <S.RedeemsContainer>
      <ShadowBox>
        <Title>Resgates</Title>
        <HR />
        <table
          className="table table-striped table-sm"
          style={{ fontSize: 13 }}
        >
          <thead>
            <tr>
              <td>ID</td>
              <td>Data</td>
              <td>Prêmio</td>
              <td>Status</td>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 && (
              <tr>
                <td colSpan={5}>Nenhum resgate</td>
              </tr>
            )}
            {data.map((linha) => {
              return (
                <tr key={linha.id}>
                  <td>{linha.id}</td>
                  <td>{moment(linha.created_at).format("DD/MM/YYYY")}</td>
                  <td>{linha.prize === 1 ? "Primeiro" : "Segundo"}</td>
                  <td>{renderStatus(linha.status)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </ShadowBox>
    </S.RedeemsContainer>
  );
}

function Extrato({ data }) {
  return (
    <S.ExtractContainer>
      <ShadowBox>
        <Title>Extrato</Title>
        <HR />
        <table
          className="table table-striped table-sm"
          style={{ fontSize: 13 }}
        >
          <thead>
            <tr>
              <td>Pontos</td>
              <td>ID</td>
              <td>Data</td>
              <td></td>
              <td style={{ width: 20 }}></td>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 && (
              <tr>
                <td colSpan={6}>Nenhuma transação</td>
              </tr>
            )}
            {data.map((linha) => {
              return <LinhaExtrato linha={linha} key={linha.id} />;
            })}
          </tbody>
        </table>
      </ShadowBox>
    </S.ExtractContainer>
  );
}

function LinhaExtrato({ linha }) {
  console.log("linha", linha);

  const cor = linha.operation === "C" ? "green" : "red";
  const pre = linha.operation === "C" ? "+" : "";

  const light =
    linha.operation === "C" ? colors.lighter_green : colors.lighter_red;

  return (
    <tr className="pointer" style={{ backgroundColor: light }}>
      <td style={{ color: cor, fontWeight: 600, backgroundColor: light }}>
        {pre}
        {linha.points} pontos
      </td>
      <td style={{ backgroundColor: light }}>{linha.document}</td>
      <td style={{ backgroundColor: light }}>
        {moment(linha.date).format("DD/MM/YYYY HH:mm:ss")}
      </td>
      <td style={{ backgroundColor: light }}>
        {linha.operation === "C" && <FontAwesomeIcon icon={faFileAlt} />}
      </td>
      <td style={{ backgroundColor: light }}>
        <FontAwesomeIcon icon={faAngleRight} />
      </td>
    </tr>
  );
}

export default Detalhes;
