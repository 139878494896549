import styled from "styled-components";

export const Container = styled.div``;
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, minmax(1fr,auto));
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

export const BasicContainer = styled.div`
  grid-area: 1 / 1 / 2 / 4;
`;

export const PointsContainer = styled.div`
  grid-area: 1 / 4 / 2 / 5;
`;

export const BalanceContainer = styled.div`
  grid-area: 1 / 5 / 2 / 6;
`;

export const PrescriptionsContainer = styled.div`
  grid-area: 2 / 1 / 3 / 4;
`;

export const RedeemsContainer = styled.div`
  grid-area: 2 / 4 / 3 / 6;
`;

export const ExtractContainer = styled.div`
  grid-area: 3 / 1 / 4 / 6;
`;

