import styled from "styled-components";
import { colors } from "../../utils/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${colors.main};
  border-radius: 15px;
  overflow: hidden;
  color: ${colors.main};
  margin-top: 10px;
  
`;

export const Option = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: ${(props) => (props.isSelected ? colors.main : "#fff")};
  color: ${(props) => (props.isSelected ? "#fff" : colors.main)};
  transition: all 0.8s ease-in-out;
  cursor: pointer;
  
  & + & {
    border-left: 1px solid ${colors.main};
  }
  
`;

export const OptionTitle = styled.div``;

export const OptionIcon = styled.div`
  font-size: 30px;
`;
