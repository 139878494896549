import styled from "styled-components";

export const Container = styled.div``;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, minmax(100px, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

export const NameContainer = styled.div`
  grid-area: 1 / 1 / 2 / 3;
  display: flex;
  flex-direction: column;
`;

export const PointsContainer = styled.div`
  grid-area: 1 / 3 / 2 / 5;
  display: flex;
  flex-direction: column;
`;
export const ImageContainer = styled.div`
  grid-area: 1 / 5 / 3 / 6;
  display: flex;
  flex-direction: column;
`;

export const DescriptionContainer = styled.div`
  grid-area: 2 / 1 / 3 / 5;
  display: flex;
  flex-direction: column;
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #d4d4d4;
    cursor: pointer;
`;

export const ImagePlaceholder = styled.div`
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #d4d4d4;
    cursor: pointer;
`;

export const Bottom = styled.div`
grid-area: 3 / 1 / 4 / 6;
display: flex;
flex-direction: column;
`