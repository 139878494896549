import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Badge } from "reactstrap";

function PrescriptionStatusBadge({ status }) {
  switch (status) {
    case 'approved':
      return (
        <Badge
          color="success"
        >
          Conferido
        </Badge>
      );
    case 'created':
      return (
        <Badge
          color="info"
        >
          Criada
        </Badge>
      );
    case 'declined':
      return (
        <Badge
          color="danger"
        >
          Recusado
        </Badge>
      );
    case 'uploaded':
      return (
        <Badge
          color="success"
        >
          Enviado
        </Badge>
      );
    default:
      return (
        <Badge
          color="info"
        >
          {status}
        </Badge>
      );
  }
}

export default PrescriptionStatusBadge;
