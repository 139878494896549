import styled from "styled-components";

export const Container = styled.div``;

export const Card = styled.div`
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 0 5px #ccc;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 20px;
  overflow: hidden;
  height: 200px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 0px 10px 0px rgba(56, 56, 56, 0.5);
  // make it blakc and white when disabled

`;

export const CardLeftSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ccc;
  /* filter: ${(props) => (props.disabled ? "grayscale(95%)" : "none")}; */
`;

export const CardRightSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-direction: column;
  padding: 20px;
`;
