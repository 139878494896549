import "./App.css";

// eslint-disable-next-line no-unused-vars
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import Routes from "./routes";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MadeForMobile from "./components/MadeForMobile";

import ReactGA from "react-ga4";
import { useEffect } from "react";

import BG1 from "./assets/images/bg1.png";
import BG_SUCCESS from "./assets/images/successbg.png";
import CHANGe_PASS from "./assets/images/securitydog-orga.png";

ReactGA.initialize("G-K3TK2Y387C", { testMode: true });

function App() {
  useEffect(() => {
    init();
  }, []);

  function init() {
    // if its not dev
    if (process.env.NODE_ENV !== "development") {
      console.log = () => {};
      console.warn = () => {};
      console.error = () => {};
    }
    // preload images after 5 seconds
    setTimeout(() => {
      preloadImages();
    }, 5000);
  }

  function preloadImages() {
    const pictures = [BG1, BG_SUCCESS, CHANGe_PASS];
    pictures.forEach((picture) => {
      const img = new Image();
      img.src = picture;
    });
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MadeForMobile />
        <Routes />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </PersistGate>
    </Provider>
  );
}

export default App;
