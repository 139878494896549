import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import firebase from "../../../database/firebaseConfig";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { Users } from "../../../database/API";
const auth = firebase.auth;
export const AUTH_STATUS = {
  IDLE: "idle",
  PENDING: "pending",
  SUCCESS: "success",
  ERROR: "error",
};

const initialState = {
  admin: false,
  userData: false,
  token: null,
  refreshToken: null,
  authenticated: false,
  status: AUTH_STATUS.IDLE,
  message: "",
  shouldChangePassword: false,
  pontos: 0,
};

export const login = createAsyncThunk("users/login", async (payload) => {
  console.log("payload", payload);
  const { email, password } = payload;
  try {
    console.log("email", email);
    console.log("password", password);
    let userData = await signInWithEmailAndPassword(auth, email, password);
    // console.log("userData", userData);
    if (password === "mudesuasenha") {
      return { userData, shouldChangePassword: true };
    } else {
      return { userData, shouldChangePassword: false };
    }
  } catch (error) {
    console.log("error", error.code);
    switch (error.code) {
      case "auth/invalid-login-credentials":
        console.log("Invalid login credentials!");
        throw new Error("Email ou senha inválidos");
        break;
      case "auth/invalid-email":
        console.log("That email address is invalid!");
        throw new Error("Email inválido");
        break;
      case "auth/user-disabled":
        console.log("That user is disabled!");
        throw new Error("Usuário desabilitado");
        break;
      case "auth/user-not-found":
        console.log("That user was not found!");
        throw new Error("Usuário não encontrado");
        break;
      case "auth/wrong-password":
        console.log("That password is incorrect!");
        throw new Error("Senha incorreta");
        break;
      case "auth/too-many-requests":
        console.log("Too many requests!");
        throw new Error("Muitas tentativas, tente novamente mais tarde");
        break;
      default:
        console.log("Something went wrong!");
        throw new Error("Algo deu errado");
        break;
    }
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    restoreLogin: (state, action) => {
      state.authenticated = true;
      state.userData = action.payload;
      state.admin = action.payload.admin;
    },
    addPontos: (state, action) => {
      let pontos = parseInt(action.payload);
      state.pontos += pontos;
    },
    setPontos: (state, action) => {
      let pontos = parseInt(action.payload);
      console.log("pontos", pontos)
      state.pontos = pontos;
    },
    logout: (state) => {
      console.log("deslogando");

      signOut(auth);
      return initialState;
    },
    setAdmin: (state, action) => {
      state.admin = action.payload;
    },
    setShouldChangePassword: (state, action) => {
      state.shouldChangePassword = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.status = AUTH_STATUS.PENDING;
      })
      .addCase(login.fulfilled, (state, action) => {
        console.log("state", state);
        console.log("action", action);
        state.shouldChangePassword = action.payload.shouldChangePassword
      })
      .addCase(login.rejected, (state, action) => {
        state.status = AUTH_STATUS.ERROR;
        state.message = action.error.message;
      });
  },
});

export const refreshToken = createAsyncThunk(
  "auth/refresh",
  async (payload) => {
    console.log("atualizando o token");
    let r = await Users.USERS.get.refresh_token();
    console.log("refreshed", r);
    return r;
  }
);

// Action creators are generated for each case reducer function
export const {
  restoreLogin,
  logout,
  setAdmin,
  setShouldChangePassword,
  addPontos,
  setPontos
} = authSlice.actions;

export default authSlice.reducer;
