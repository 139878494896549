import { CallApi } from "../db";

export const PRESCRIPTIONS = {
    get: {
        byUID: async (uid) => {
            let response =await CallApi.get(`/prescriptions/by-user/${uid}`);
            return response.data
        },
        byUUID: async(uuid)=>{
            let response =await CallApi.get(`/prescriptions/by-id/${uuid}`);
            return response.data
        },
        graph: async(days)=>{
             let response =await CallApi.get(`/prescriptions/graph?days=${days}`);
             return response.data
        }
    },
    set: {
        new: async (data)=>{
            let response =await CallApi.post(`/prescriptions`, data);
            return response.data
        },
        review: async (uuid, approved)=>{
            let response =await CallApi.patch(`/prescriptions/review/${uuid}`, {approved});
            return response.data
        }
    }
}