import styled from 'styled-components';

export const Container = styled.div`
  background-image: url(${(props) => props.background});
  margin-left: -15px;
  margin-right: -15px;
  padding: 15px ;
  background-repeat: no-repeat;
  padding-top: 300px;
  background-size: contain;
  
`;


export const ImageSelector = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    text-align: center;
    color: #979797;
    min-height: 200px;
    border-radius: 5px;
    margin-top: 10px;
    object-fit: contain;
`;


export const Produto = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    & span {
        flex: 1;
    }
`;


export const ProductInfo = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
`;


export const Remove = styled.div`
    margin-right: 10px;
`;

export const BigText = styled.div`
    font-size: 20px;
    font-weight: bold;
    color: #fff;
`;

export const BigNumber = styled.div`
    font-size: 40px;
    font-weight: bold;
    color: #fff;
`;

export const SmallText = styled.div`
    font-size: 14px;
    color: #fff;
`;