import { CallApi } from "../db";

export const PRODUTOS = {
    get: {
       all: async()=>{
        let response =await CallApi.get(`/products`);
        return response.data
       },
       one: async(id)=>{
        let response = await CallApi.get(`/products/${id}`);
        return response.data
       }
    },
    set: {
        new: async (nome, imagem, descricao, pontos) => {
            let payload = {
              name: nome,
              description: descricao,
              image: imagem,
              points: pontos,
              active: true,
            };
            let response = await CallApi.post(`/products`, payload);
            return true
          },
          edit: async (id, nome, imagem, descricao, pontos) => {
            let payload = {
              name: nome,
              description: descricao,
              image: imagem,
              points: pontos,
              active: true,
            };
            let response = await CallApi.patch(`/products/${id}`, payload);
            return true
          },
          delete: async (id) => {
            let response = await CallApi.delete(`/products/${id}`);
            return true
          },
    }
}