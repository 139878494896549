import React, { useEffect, useState } from "react";

import * as S from "./styles";
import { FH, FHSB, HR, Title } from "../../../main-styles";
import { Col, Container, Row } from "reactstrap";
import { USERS } from "../../../database/API/User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faPlusCircle,
  faShield,
} from "@fortawesome/pro-light-svg-icons";
import UserStatusBadge from "../../../components/UserStatusBadge";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import Filter from "../../../components/Filter";
import ShadowBox from "../../../components/ShadowBox";
import AddBtn from "../../../components/AddBtn";

function Users() {
  const [state, setState] = useState({
    users: [],
    page: 0,
    perPage: 50,
    loading: true,
    hasmore: true,
    search: "",
    loadingMore: false,
    selected: false,
  });
  const { users, page, perPage, loading, hasmore, search, loadingMore } = state;

  useEffect(() => {
    loadUsers();

    document.title = "Usuários | Admin |  Orga&Vet";
  }, [page, perPage]);

  useEffect(() => {
    if (search === "") loadUsers();
  }, [search]);

  async function loadUsers() {
    setState((s) => ({ ...s, loadingMore: true }));
    let users = await USERS.get.all(page, perPage, search);
    console.log("users", users);
    let hasmore = users.length === perPage;
    setState((s) => ({
      ...s,
      users,
      loading: false,
      loadingMore: false,
      hasmore,
    }));
  }

  function next() {
    if (loadingMore || !hasmore || loading) return;
    setState((s) => ({ ...s, page: page + 1 }));
  }

  function previous() {
    if (loadingMore || page === 0 || loading) return;
    setState((s) => ({ ...s, page: page - 1 }));
  }

  const isLoading = loading || loadingMore;

  return (
    <ShadowBox>
        <Container fluid={true}>
          <Row>
            <Col md={12}>
              <FHSB>
                <Title>Usuários</Title>
                <AddBtn link="/admin/usuarios/novo" />
              </FHSB>
              <HR />
              <FH>
                <Filter
                  go={loadUsers}
                  needle={search}
                  onChange={(e) => setState((s) => ({ ...s, search: e }))}
                />
                <div style={{ width: 5 }} />
                <div style={{ width: 120 }}>
                  <Pagination
                    page={page}
                    hasmore={hasmore}
                    next={next}
                    previous={previous}
                  />
                </div>
              </FH>

              <table className="table table-striped" style={{ fontSize: 12 }}>
                <thead>
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Status</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">Data de cadastro</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading && (
                    <tr>
                      <td colSpan={8} style={{ textAlign: "center" }}>
                        Carregando...
                      </td>
                    </tr>
                  )}
                  {!isLoading && users.length === 0 && (
                    <tr>
                      <td colSpan={8} style={{ textAlign: "center" }}>
                        Nenhum usuário cadastrado
                      </td>
                    </tr>
                  )}
                  {users.map((user, i) => (
                    <tr key={i}>
                      <td  style={{textTransform: 'capitalize'}}>
                        <Link to={`/admin/usuarios/detalhes/${user.uid}`}>
                          {user.admin && (
                            <FontAwesomeIcon
                              icon={faShield}
                              style={{ marginRight: 8, fontSize: 15 }}
                            />
                          )}
                          {user.name?.toLowerCase()} {user.last_name.toLowerCase()}
                        </Link>
                      </td>
                      <td>
                        <UserStatusBadge status={user.status} />
                      </td>
                      <td>{user.email}</td>
                      <td>{moment(user.created_at).format("DD/MM/YYYY")}</td>
                      <td>
                        <FontAwesomeIcon icon={faAngleRight} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
        </Container>
    </ShadowBox>
  );
}

export default Users;
