import { CallApi } from "../db";
import { userData, token as getToken } from "../helper";

export const USERS = {
  get: {
    me: async (uid,token) => {
      console.log("token", token)
      if(!token) token = await getToken()
      if(!token) return null;
      if(!uid) {
        uid = userData();
        console.log("uid", uid)
        uid = uid.uid;
      };
      let response = await CallApi.get(`/users/byId/${uid}`, token);
      console.log("response", response)
      return response.data;
    },
    all: async (page = 0, perPage = 50, search = "") => {
      try {
        let response = await CallApi.get(
          `/users?limit=${perPage}&offset=${page}&search=${search}`
        );
        return response.data;
      } catch (error) {
        return [];
      }
    },
    one: async (uid) => {
      let response = await CallApi.get(`/users/byId/${uid}`);
      return response.data;
    },
    refresh_token: async () => {
      return true;
    },
    orgalovers: async () => {
      let response = await CallApi.get(`/users/orgalovers`);
      return response.data;
    },
  },
  set: {
    newVet: async (nome, email, id_vet) => {
      let response = await CallApi.post(`/users`, {
        name: nome.trim(),
        email: email.toLowerCase().trim(),
        password: "mudesuasenha",
        id_vet,
      });
      return response.data;
    },

    newAdmin: async (nome, email) => {
      let response = await CallApi.post(`/users`, {
        name: nome.trim(),
        email: email.toLowerCase().trim(),
        password: "mudesuasenha",
        admin: true,
      });
      return response.data;
    },
    isEmailAvailable: async (email) => {
      try {
        let response = await CallApi.get(`/users/check-email/${email}`);
        return true;
      } catch (error) {
        return false;
      }
    },
  },
};
