import styled from "styled-components";
import { colors } from "../../utils/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  overflow: hidden;
  color: ${colors.main};
  margin-top: 10px;
`;

export const Option = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: ${(props) => (props.isSelected ? colors.main : "#fff")};
  color: ${(props) => (props.isSelected ? "#fff" : colors.main)};
  transition: all 0.8s ease-in;
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 130px;
  cursor: pointer;
  color: #fff;
  // make it black and white if not selected
  filter: ${(props) => (props.isSelected ? "none" : "grayscale(100%)")};
  // make it a bit black tinted if not selected
  opacity: ${(props) => (props.isSelected ? "1" : "0.7")};
  &:hover {
    background-color: ${colors.main};
    filter: none;
  }
  & span {
    background-color: ${colors.main};
    width: 100%;
    text-align: center;
  }
`;

export const OptionTitle = styled.div``;

export const OptionIcon = styled.div`
  font-size: 30px;
`;
