import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth/authSlice";

import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};

const baseReducer = combineReducers({ 
    auth: authReducer,
  })
  

const persistedReducer = persistReducer(persistConfig, baseReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);
export { store, persistor };
